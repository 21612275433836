/* eslint-disable class-methods-use-this */
import { logger } from '@tecma/logs-fe';

class ProjectAPI {
  baseUserURL: string;

  initRequest: RequestInit;

  constructor() {
    this.baseUserURL = `${process.env.REACT_APP_PROJECT_API_BASE_URL}`;
    this.initRequest = {
      referrerPolicy: 'unsafe-url',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  async getMyLivingConfigByProject(projectId: string) {
    try {
      const response = await fetch(
        `${this.baseUserURL}myLiving/findByProjectId/${projectId}`,
        {
          ...this.initRequest,
          method: 'GET',
        },
      );
      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }
      return response;
    } catch (err) {
      logger.error(err);
      throw Error();
    }
  }
}

export default new ProjectAPI();
