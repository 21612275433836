import { gql } from '@apollo/client';

const PROJECT_DATA_TO_RETRIEVE = `
  id
  name
  logo
  vendorLogo
  assetLogin
  hostKey
  assetKey
  feVendorKey
  assetsUrl
  displayName
  manifestConfig
  locale
  style
`;

export const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!) {
    getProjectInfoByHost (host :$host) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

export interface GetProjectInfoOptions {
  variables: {
    host: string;
  };
  notifyOnNetworkStatusChange: boolean;
}

export const GET_PROJECT_INFO_DEFAULT_OPTIONS = (
  hostname: string,
): GetProjectInfoOptions => ({
  variables: { host: hostname },
  notifyOnNetworkStatusChange: true,
});
