/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Resizer from 'react-image-file-resizer';
import { TFunction } from '@tecma/i18n';

import navigationRoutes from 'routes/navigation';
import {
  EXTRA_INFO_SERVICES,
  WEATHER_CONDITIONS_CODES,
  NEW_NOTIFICATION_REQUEST_ITEMS,
  COMMUNICATIONS_ITEMS,
  ENGLISH_DAYS,
  CommunicationAndNotificationKey,
} from 'constant';
import { InfoState } from 'store/slices/info';
import { NotificationClientBreakdown } from 'types/notification';
import {
  ICommunityCalendar,
  ICommunityService,
  TimeRangeInput,
} from 'types/activity';

export const getMyHomeUrl = (info: InfoState) => {
  return `https://${info.hostKey}/${process.env.REACT_APP_TOOL_URL}/?`;
};

export const detectUrl = () => {
  let hostname =
    window.location.hostname.indexOf('www.') >= 0
      ? window.location.hostname
      : `www.${window.location.hostname}`;
  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');
  if (
    hostname.indexOf('localhost') >= 0 ||
    hostname.indexOf('.ddns.net') >= 0 ||
    hostname.indexOf('biz-tecma') >= 0 ||
    hostname.indexOf('-demo.tecmasolutions.com') >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      localStorage.clear();

      delete localStorage.myliving_dev_hostname;

      localStorage.setItem('myliving_dev_hostname', hostnameParam);
    }

    const mylivingDevHostname = localStorage.getItem('myliving_dev_hostname');

    if (
      mylivingDevHostname &&
      mylivingDevHostname.length > 0 &&
      mylivingDevHostname !== ''
    ) {
      return mylivingDevHostname;
    }
    hostname = process.env.REACT_APP_HOST || '';
  }
  return hostname;
};

export const hexToRGB = (hexColor: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  return result
    ? `${
        (parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16))
      }`
    : null;
};

export const disableSidebarMenu = (pathName: string) => {
  const publicRoute = navigationRoutes
    .filter((route) => !route.private)
    .map((value) => value.path);
  return publicRoute.includes(pathName) || pathName === '/welcome';
};

export const getAnimationClass = (
  currentSection: string,
  nextSection: string,
) => {
  const sectionName = ['foryou', 'myLifeStyle', 'notices', 'myRequest'];
  return sectionName.indexOf(currentSection) > sectionName.indexOf(nextSection)
    ? 'animate__fadeInRight'
    : 'animate__fadeInLeft';
};

export const getWeatherIcon = (code: number, isDay: number) => {
  const dayMoment = isDay ? 'day' : 'night';
  return WEATHER_CONDITIONS_CODES[code][dayMoment].icon;
};

export const getNotificationBreakdown = (
  value: CommunicationAndNotificationKey,
  notificationType: 'CM' | 'CLIENT' = 'CLIENT',
) => {
  const icons =
    notificationType === 'CLIENT'
      ? NEW_NOTIFICATION_REQUEST_ITEMS
      : COMMUNICATIONS_ITEMS;
  const requestItem = icons[value];
  const defaultIcon = icons.OTHER;
  return requestItem ?? defaultIcon;
};

export const setNotificationClientBreakdown = (type: string) => {
  switch (type) {
    case 'furniture':
      return NotificationClientBreakdown.FORNITURE;
    case 'window':
      return NotificationClientBreakdown.WINDOW;
    case 'heating':
      return NotificationClientBreakdown.CONDITIONING;
    case 'bathroom':
      return NotificationClientBreakdown.BATHROOM;
    case 'roller-shutter':
      return NotificationClientBreakdown.ROLLER_SHUTTER;
    default:
      return NotificationClientBreakdown.OTHER;
  }
};

export const createBulletsHtmlElements = (
  elements: ICommunityCalendar[],
): string[] => {
  return elements.map((service, i) => {
    if (i < 5 && service.service) {
      const serviceColor =
        EXTRA_INFO_SERVICES[service.service.name.toLowerCase()]?.color ||
        'grey';
      return `<div class="my-calendar-bullet-service" style="left: ${
        i * 7
      }px; background-color: ${serviceColor} "></div>`;
    }
    return '';
  });
};

export const getTime = (date: string) => {
  const dateFormatted = new Date(date);
  const hours = dateFormatted.getHours().toString();
  const minutes = String(dateFormatted.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getBucketOrBase64UrlImage = (urlImage: string) => {
  return urlImage.startsWith('data:') || urlImage.startsWith('https:')
    ? urlImage
    : urlImage &&
        `${process.env.REACT_APP_BUCKET_BASEURL}/${encodeURI(urlImage)}`;
};

export const resizeFile = (
  file: Blob,
  qualityImg = 100,
  outputFormat: 'base64' | 'blob' | 'file' = 'base64',
): Promise<string | Blob | File | ProgressEvent<FileReader>> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      qualityImg,
      0,
      (uri) => {
        resolve(uri);
      },
      outputFormat,
    );
  });

export const shadeColor = (color: string, amount: number) => {
  const newHEXColorValue = `#${color
    .replace(/^#/, '')
    .replace(/../g, (newColor) =>
      Math.min(255, Math.max(0, parseInt(newColor, 16) + amount)).toString(16),
    )}`;
  return `0${newHEXColorValue}`.substring(1);
};

export const getServiceName = (
  t: TFunction<'translation', undefined>,
  serviceName: string,
) => {
  return serviceName === 'Gym' ? t('gym-space') : serviceName;
};

export const getDateSlots = (
  date: Date,
  service: ICommunityService,
): string[] => {
  const slots = service.opening_hours?.reduce<string[]>((slotsAcc, value) => {
    if (date && ENGLISH_DAYS[date.getDay()].toUpperCase() === value.day) {
      slotsAcc.push(
        ...value.ranges.reduce<string[]>((acc, range: TimeRangeInput) => {
          const rangeTo =
            range.to.hours.toString() +
            String(range.from.minutes).padStart(2, '0');
          const rangeFrom =
            range.from.hours.toString() +
            String(range.from.minutes).padStart(2, '0');
          const slotsNumber =
            (parseInt(rangeTo, 10) - parseInt(rangeFrom, 10)) / 100;
          const slotsArray = Array(slotsNumber).fill(0);
          acc.push(
            ...slotsArray.map((_, i) => {
              return `${String(range.from.hours + i)}.${String(
                range.from.minutes,
              ).padStart(2, '0')} - ${
                range.from.hours +
                (service?.time_slot ? service.time_slot / 60 : 1) +
                i
              }.${String(range.from.minutes).padStart(2, '0')}`;
            }),
          );
          return acc;
        }, []),
      );
    }
    return slotsAcc;
  }, []);
  return slots?.length ? slots : [];
};

export const loadScssFromDB = ({
  style: initiativesStyles,
}: InfoState): void => {
  const loadColorsStyle = (colorsStyle: { [key: string]: string }) => {
    if (colorsStyle) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(colorsStyle)) {
        if (colorsStyle[key] !== '') {
          if (
            [
              'border-radius-element',
              'border-radius-external',
              'border-radius-internal',
              'border-radius-standard',
              'border-width-element',
              'border-width-external',
              'border-width-internal',
              'border-width-standard',
            ].includes(key)
          ) {
            document.documentElement.style.setProperty(
              `--${key}`,
              `${colorsStyle[key]}px`,
            );
          } else {
            document.documentElement.style.setProperty(
              `--${key}`,
              colorsStyle[key],
            );
          }
        }
      }
    }
  };
  loadColorsStyle(initiativesStyles ?? {});
};

export const imageExist = (imageUrl: string) => {
  const http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, false);
  http.send();

  return http.status !== 404;
};
