import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { useMutation } from '@apollo/client';
import { chevronBackOutline } from 'ionicons/icons';
import { useTranslation } from '@tecma/i18n';
import FullCalendar, {
  DayCellContentArg,
  LocaleInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { DateTime } from 'luxon';

import Client from 'client';
import { clientID } from 'store/slices/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectID } from 'store/slices/info';
import { createAlert } from 'store/slices/alert';
import { apartmentID } from 'store/slices/apartments';
import { useIsMounted } from 'hooks';
import { IBookingService, ICommunityCalendar } from 'types/activity';
import { createBulletsHtmlElements } from 'utils';
import BookingTicket from 'components/BookingTicket';
import './my-booking-calendar.scss';
import useGetBookingList from 'hooks/useGetBookingList';

interface MyBookingCalendarProps {
  onClose: MouseEventHandler<
    HTMLIonButtonElement | HTMLIonIconElement | HTMLDivElement
  >;
}

const MyBookingCalendar: React.FC<MyBookingCalendarProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const projectId = useAppSelector(projectID);
  const client = useAppSelector(clientID);
  const appartmentId = useAppSelector(apartmentID);
  const daySelectedRef = useRef<HTMLElement | null>(null);
  const calendarRef = useRef<FullCalendar>(null);
  const locales: LocaleInput[] = [enLocale, itLocale];
  const isMounted = useIsMounted();
  const [dateSelected, setDateSelected] = useState<Date | undefined>(
    new Date(),
  );
  const [currentDateAppointments, setCurrentDateAppointments] = useState<
    IBookingService[]
  >([]);
  const {
    data: calendarData,
    loading,
    refetchBookings,
  } = useGetBookingList({ client });

  const [deleteCommunityCalendar, { loading: deleteLoading }] = useMutation(
    Client.DELETE_COMMUNITY_CALENDAR,
  );

  useEffect(() => {
    if (calendarData && !loading) {
      setTimeout(() => {
        calendarRef.current?.getApi().updateSize();
      }, 100);
    }
  }, [calendarData, loading]);

  useEffect(() => {
    if (dateSelected && isMounted.current) {
      setCurrentDateAppointments(getBookingServicesList(dateSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  const handleDateClick = (arg: DateClickArg) => {
    if (arg.dayEl.classList.contains('disabled-date')) {
      setDateSelected(undefined);
      if (daySelectedRef.current) {
        daySelectedRef.current.classList.remove('selected-date');
      }
      return;
    }
    setCurrentDateAppointments([]);
    setDateSelected(arg.date);
    if (daySelectedRef.current) {
      daySelectedRef.current.classList.remove('selected-date');
    }
    daySelectedRef.current = arg.dayEl;
    daySelectedRef.current.classList.add('selected-date');
  };

  const getBookingDays = (
    arg: DayCellContentArg,
  ): { html: string } | undefined => {
    const { date } = arg;
    const reservedSlots = calendarData.reduce<ICommunityCalendar[]>(
      (acc, service) => {
        const activityDate = new Date(service?.startDate);
        const activityDateFormatted = `${activityDate.getDate()},${
          activityDate.getMonth() + 1
        },${activityDate.getFullYear()}`;
        const dayOfCalendarFormatted = `${date.getDate()},${
          date.getMonth() + 1
        },${date.getFullYear()}`;
        if (activityDateFormatted === dayOfCalendarFormatted) {
          acc.push(service);
        }
        return acc;
      },
      [],
    );
    if (reservedSlots.length) {
      const bulletsElements = createBulletsHtmlElements(reservedSlots);
      return {
        html: `<a class="fc-daygrid-day-number">${
          reservedSlots[0]?.startDate
            ? new Date(reservedSlots[0]?.startDate).getDate()
            : null
        }</a><div class="my-calendar-bullets-container">${
          bulletsElements.join() ? bulletsElements.join().replace(/,/g, '') : ''
        }</div>`,
      };
    }
    return undefined;
  };

  const handleDeleteServiceDate = (serviceSelectedID: string) => {
    const id = serviceSelectedID;
    try {
      deleteCommunityCalendar(
        Client.DELETE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS(
          id,
          projectId,
          appartmentId,
        ),
      ).then(async ({ data }) => {
        if (data.deleteCommunityCalendar.error) {
          dispatch(
            createAlert({
              type: 'error',
              message: t('errors.activity-error'),
            }),
          );
        } else {
          dispatch(
            createAlert({
              type: 'success',
              message: t('alerts.activity-success'),
            }),
          );
          refetchBookings();
          setCurrentDateAppointments((currentValue) => {
            const result = currentValue.filter(
              (bookingService) => bookingService.calendarID !== id,
            );
            return result;
          });
        }
      });
    } catch (e) {
      dispatch(
        createAlert({
          type: 'error',
          message: t('errors.generic'),
        }),
      );
    }
  };

  const handleChangeMonthView = (prevOrNext: 'prev' | 'next') => {
    setDateSelected(undefined);
    if (prevOrNext === 'prev') {
      calendarRef.current?.getApi().prev();
    } else {
      calendarRef.current?.getApi().next();
    }
    if (calendarRef.current) {
      const { month, year } = DateTime.fromJSDate(
        calendarRef?.current.getApi().getCurrentData().currentDate,
      );
      const lastDateInCurrentMonth = new Date(year, month, 0);
      const firstDateInCurrentMonth = DateTime.fromJSDate(
        lastDateInCurrentMonth,
      )
        .set({ day: 1 })
        .toISO();
      refetchBookings({
        filter: {
          client,
          startDate: firstDateInCurrentMonth,
          endDate: DateTime.fromJSDate(lastDateInCurrentMonth)
            .set({ hour: 24 })
            .toISO(),
        },
      });
    }
  };

  const getBookingServicesList = (date: Date): IBookingService[] => {
    return calendarData.reduce<IBookingService[]>((acc, service) => {
      if (service?.startDate) {
        const dataService = new Date(service?.startDate);
        if (
          dataService.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0) &&
          service.service
        ) {
          acc.push({
            ...service.service,
            time_slot: service.service.time_slot || 0,
            calendarID: service.id,
            startDate: service.startDate,
            endDate: service.endDate,
          });
        }
      }
      return acc;
    }, []);
  };

  return (
    <IonContent
      data-testid="myBookingCalendar-container"
      className="my-booking-calendar-content"
    >
      <IonGrid className="my-booking-calendar-grid">
        <IonRow className="my-booking-calendar-row ion-justify-content-center">
          <IonCol size="11">
            <IonIcon
              data-testid="myBookingCalendar-button"
              className="my-booking-calendar-back"
              onClick={onClose}
              icon={chevronBackOutline}
            />
          </IonCol>
          <IonCol size="12">
            <IonText className="my-booking-calendar-title ion-text-uppercase">
              <p>{t('myliving-calendar')}</p>
            </IonText>
          </IonCol>
          <IonCol size="12" className="ion-no-padding">
            <div
              data-testid="myCalendar-container"
              className="my-calendar-content"
            >
              <FullCalendar
                ref={calendarRef}
                locale={locale}
                locales={locales}
                rerenderDelay={500}
                aspectRatio={1}
                showNonCurrentDates={false}
                height="320px"
                contentHeight="320px"
                nowIndicator
                plugins={[dayGridPlugin, interactionPlugin]}
                dateClick={handleDateClick}
                headerToolbar={{
                  left: 'prevButton',
                  center: 'title',
                  right: 'nextButton',
                }}
                customButtons={{
                  prevButton: {
                    icon: 'chevron-left',
                    click: () => {
                      handleChangeMonthView('prev');
                    },
                  },
                  nextButton: {
                    icon: 'chevron-right',
                    click: () => {
                      handleChangeMonthView('next');
                    },
                  },
                }}
                fixedWeekCount={false}
                initialView="dayGridMonth"
                dayCellContent={getBookingDays}
                selectAllow={() => true}
                eventOverlap={false}
              />
              {loading && (
                <div className="my-booking-loader">
                  <IonSpinner name="crescent" />
                </div>
              )}
            </div>
            <IonGrid>
              <IonRow className="ion-align-items-center ion-justify-content-center">
                <IonCol size="11">
                  <IonText className="my-calendar-bookings-title">
                    <p>{t('your-dates')}</p>
                  </IonText>
                </IonCol>
                <IonCol size="11">
                  {dateSelected && currentDateAppointments.length ? (
                    <IonList className="my-calendar-bookings-list">
                      {currentDateAppointments.map((service) => (
                        <BookingTicket
                          key={`${service.id}${service.startDate}`}
                          loading={deleteLoading}
                          deleteServiceFn={handleDeleteServiceDate}
                          serviceSelected={service}
                        />
                      ))}
                    </IonList>
                  ) : (
                    <IonText className="my-calendar-bookings-no-bookings">
                      <p>{t('no-dates')}</p>
                    </IonText>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default MyBookingCalendar;
