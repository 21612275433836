import React, { useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { DateTime } from 'luxon';
import { useTranslation } from '@tecma/i18n';

import { ICommunityCalendarSlot, ICommunityService } from 'types/activity';
import { EXTRA_INFO_SERVICES } from 'constant';
import { getTime } from 'utils';
import ActionAlert from 'components/ActionAlert';
import './booking-ticket.scss';

interface BookingTicketProps {
  serviceSelected: ICommunityService & {
    startDate?: string;
    endDate?: string;
    calendarID?: string;
  };
  deleteServiceFn?: (serviceSelectedID: string) => void;
  slotSelected?: ICommunityCalendarSlot;
  loading?: boolean;
}

const BookingTicket: React.FC<BookingTicketProps> = ({
  serviceSelected,
  slotSelected = { startDate: '', endDate: '' },
  loading,
  deleteServiceFn,
}) => {
  const { t } = useTranslation();
  const [deleteActivity, setDeleteActivity] = useState<string>();
  const [showActionAlert, setShowActionAlert] = useState(false);

  const isPastService = () => {
    return serviceSelected.startDate
      ? DateTime.fromISO(serviceSelected.startDate) < DateTime.now()
      : false;
  };

  const getBookingDate = (
    dateTypeKey: 'startDate' | 'endDate',
    defaultValue: string,
  ) => {
    const date =
      serviceSelected[dateTypeKey] ?? slotSelected[dateTypeKey] ?? null;
    if (date) {
      return t('date.booking-ticket', { date: new Date(date) });
    }
    return defaultValue;
  };

  const getScheduleTime = (defaultValue: string) => {
    const startDate =
      serviceSelected.startDate ?? slotSelected.startDate ?? null;
    const endDate = serviceSelected.endDate ?? slotSelected.endDate ?? null;
    if (startDate && endDate) {
      return t('date.from-to', {
        startDate: getTime(startDate),
        endDate: getTime(endDate),
      });
    }
    return defaultValue;
  };

  return (
    <>
      <IonGrid
        data-testid="bookingTicket-container"
        className="booking-ticket-container"
        style={{
          borderBottom: `10px solid ${
            EXTRA_INFO_SERVICES[serviceSelected.name.toLowerCase()].color
          }`,
        }}
      >
        <IonRow className="ion-align-items-center ion-justify-content-start">
          <IonCol size="10">
            <IonText>
              <p className="booking-ticket-title ion-text-left">
                {t('reservation')}
              </p>
              <p
                data-testid="bookingTicket-textName"
                className="booking-ticket-subtitle ion-text-left"
              >
                {t('service-name', {
                  serviceName: serviceSelected.name.toLowerCase(),
                })}
              </p>
            </IonText>
          </IonCol>
          {deleteServiceFn && !isPastService() && (
            <IonCol className="booking-ticket-col-icon" size="2">
              {loading && deleteActivity ? (
                <div>
                  <IonSpinner name="crescent" />
                </div>
              ) : (
                <IonIcon
                  onClick={() => !loading && setShowActionAlert(true)}
                  style={{
                    color:
                      EXTRA_INFO_SERVICES[serviceSelected.name.toLowerCase()]
                        .color,
                  }}
                  className="booking-ticket-delete-icon"
                  src="assets/svg/trash.svg"
                />
              )}
            </IonCol>
          )}
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonCol size="3">
            <IonIcon
              className="booking-ticket-icon"
              style={{
                color:
                  EXTRA_INFO_SERVICES[serviceSelected.name.toLowerCase()].color,
              }}
              src={`assets/svg/${
                EXTRA_INFO_SERVICES[serviceSelected.name.toLowerCase()].icon
              }`}
            />
          </IonCol>
          <IonCol className="booking-ticket-middle-col">
            {serviceSelected.allow_multiple_slot ? (
              <IonText className="booking-ticket-date">
                <p className="ion-text-left date-title">{t('check-in')}</p>
                <p className=" ion-text-left date-value">
                  {getBookingDate('startDate', '-')}
                </p>
              </IonText>
            ) : (
              <IonText className="booking-ticket-date">
                <p className="ion-text-left date-title">{t('date-title')}</p>
                <p className=" ion-text-left date-value">
                  {getBookingDate('startDate', t('unavailable'))}
                </p>
              </IonText>
            )}
          </IonCol>
          <IonCol>
            {serviceSelected.allow_multiple_slot ? (
              <IonText className="booking-ticket-hours">
                <p className="ion-text-left hours-title">{t('check-out')}</p>
                <p className=" ion-text-left hours-value">
                  {getBookingDate('endDate', '-')}
                </p>
              </IonText>
            ) : (
              <IonText className="booking-ticket-hours">
                <p className=" ion-text-left hours-title">{t('schedule')}</p>
                <p className=" ion-text-left hours-value">
                  {serviceSelected.time_slot === 0
                    ? t('all-day')
                    : getScheduleTime('-')}
                </p>
              </IonText>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
      {showActionAlert && deleteServiceFn && (
        <ActionAlert
          isOpen={showActionAlert}
          mode="ios"
          subHeader={t('alerts.reservation-header')}
          message={`<p>${t('alerts.message')}</p>`}
          buttons={[
            {
              text: t('alerts.reservation-cancel'),
              cssClass: 'confirm-button',
              role: 'destructive',
              handler: () => {
                setShowActionAlert(false);
              },
            },
            {
              text: t('alerts.reservation-confirm'),
              cssClass: 'cancel-button',
              role: 'cancel',
            },
          ]}
          onWillDismiss={(e) => {
            if (e.detail.role === 'cancel') {
              setDeleteActivity(serviceSelected?.calendarID);
              deleteServiceFn(serviceSelected?.calendarID || '');
            }
            setShowActionAlert(false);
          }}
        />
      )}
    </>
  );
};

export default BookingTicket;
