import React from 'react';
import { IonIcon, IonText } from '@ionic/react';

import './error-handler.scss';
import { reloadOutline } from 'ionicons/icons';

interface ErrorHandlerProps {
  refetchFn: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ refetchFn }) => {
  return (
    <>
      <div
        data-testid="errorHandler-container"
        aria-hidden="true"
        role="button"
        tabIndex={0}
        onClick={() => refetchFn()}
        className="error-handler-content"
      >
        <IonText>
          <p className="error-handler-content-title">
            Qualcosa è andato storto.
          </p>
        </IonText>
        <IonIcon icon={reloadOutline} />
        <IonText>
          <p className="error-handler-content-action">Riprova</p>
        </IonText>
      </div>
    </>
  );
};

export default ErrorHandler;
