import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAlert {
  message: string;
  type: 'success' | 'error' | 'warning';
  duration?: number;
}
export interface IAlertState {
  alerts: IAlert[];
}

export const initialState: IAlertState = {
  alerts: [],
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, action: PayloadAction<IAlert>) => {
      state.alerts.push(action.payload);
    },
  },
});

export const { createAlert } = alertSlice.actions;

export default alertSlice.reducer;
