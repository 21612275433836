import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/Login'));
const ConfirmPassword = React.lazy(() => import('../pages/ConfirmPassword'));
const ChangePassword = React.lazy(() => import('../pages/ChangePassword'));
const Welcome = React.lazy(() => import('../pages/Welcome'));

export interface ILocationState {
  from?: {
    pathname: string;
  };
}

export interface INavigationRoute {
  name: string;
  exact: boolean;
  path: string;
  private: boolean;
  component: React.FC<RouteComponentProps>;
}

const navigationRoutes: INavigationRoute[] = [
  {
    path: '/welcome',
    exact: true,
    name: 'Welcome',
    component: Welcome,
    private: true,
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
    private: false,
  },
  {
    path: '/confirm',
    exact: true,
    name: 'ConfirmPassword',
    component: ConfirmPassword,
    private: false,
  },
  {
    path: '/changePassword',
    exact: true,
    name: 'ChangePassword',
    component: ChangePassword,
    private: false,
  },
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Home,
    private: true,
  },
];

export default navigationRoutes;
