import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    firstName
    client
    project_ids
  }
`;
export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    id
    subject
    message
    type
    notice
    priority
    breakdown
    read
    buildingOnly
    validated
    archived
    cancelled
    createdOn
    createdBy
    updatedOn
    deletedOn
    attachments {
      type
      base64
    }
    appartment
    building
    project_id
  }
`;

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    firstName
    lastName
    email
    status
    avatar
    tel
    city
    birthdate
    cookies
    note
    createdOn
  }
`;

export const ERRORS_FRAGMENT = gql`
  fragment MyLivingErrorFragment on MyLivingError {
    message
    error
  }
`;

export const TOKEN_FRAGMENT = gql`
  fragment TokenFragment on ResponseToken {
    tokenType
    accessToken
    refreshToken
    expiresIn
  }
`;

export const COMMUNITY_SERVICE_FRAGMENT = gql`
  fragment CommunityServiceFragment on CommunityService {
    id
    name
    description
    address
    images
    max_reservations
    time_slot
    allow_multiple_slot
    private_service
    open
    opening_hours {
      day
      ranges {
        from {
          hours
          minutes
        }
        to {
          hours
          minutes
        }
      }
    }
    project_id
  }
`;

export const COMMUNITY_CALENDAR_FRAGMENT = gql`
  fragment CommunityCalendarFragment on CommunityCalendar {
    id
    title
    startDate
    endDate
    service {
      ...CommunityServiceFragment
    }
    reservation_type
    google_id
    microsoft_id
    notes
    client {
      ...ClientFragment
    }
    project_id
  }
`;

export const BOOLEAN_RESULT_FRAGMENT = gql`
  fragment BooleanResultFragment on BooleanResult {
    done
  }
`;
