import { useAppSelector } from 'store/hooks';
import { clientID } from 'store/slices/auth';
import { projectID } from 'store/slices/info';
import { getAllMessages, getNewMessages } from 'store/slices/messages';
import { MessageData } from 'types/message';
import { MessagesInput } from 'client/modules/notifications';
import { useImperativeQuery } from 'hooks';
import Client from 'client';

const useMessagesList = () => {
  const messages = useAppSelector(getAllMessages);
  const newMessages = useAppSelector(getNewMessages);
  const messagesInput: MessagesInput = {
    client: useAppSelector(clientID),
    project_id: useAppSelector(projectID),
    showExpired: false,
  };
  const messagesQuery = useImperativeQuery<MessageData>(
    Client.GET_MESSAGES_LIST,
    Client.GET_MESSAGES_LIST_DEFAULT_OPTIONS(messagesInput),
  );

  return {
    data: messages,
    newMessages,
    refetchMessages: messagesQuery,
  };
};

export default useMessagesList;
