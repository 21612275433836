/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import {
  CLIENT_FRAGMENT,
  COMMUNITY_CALENDAR_FRAGMENT,
  COMMUNITY_SERVICE_FRAGMENT,
  ERRORS_FRAGMENT,
  BOOLEAN_RESULT_FRAGMENT,
} from '../fragments';

// eslint-disable-next-line no-shadow
export enum ServiceReservationType {
  WHOLE_STRUCTURE = 'WHOLE_STRUCTURE',
  SINGLE = 'SINGLE',
}

export interface UpdateOptions {
  variables: {
    id: string;
    input: UpdateCommunityCalendarInput;
  };
}

export interface CreateCommunityOptions {
  variables: {
    input: CreateCommunityCalendarInput;
  };
  fetchPolicy: 'no-cache';
}

export interface GetCalendarsOptions {
  variables: {
    project_id: string;
    filter: CommunityCalendarFilterInput;
  };
  fetchPolicy: 'no-cache';
  notifyOnNetworkStatusChange: boolean;
}
export interface GetCalendarsByProjectOptions {
  variables: {
    project_id: string;
  };
  notifyOnNetworkStatusChange: boolean;
}

export interface CommunityCalendarFilterInput {
  client?: string;
  service?: string;
  startDate?: string;
  endDate?: string;
  reservation_type?: ServiceReservationType;
}

export interface UpdateCommunityCalendarInput {
  project_id: string;
  appartment_id: string;
  title?: string;
  startDate?: string;
  endDate?: string;
  reservation_type?: ServiceReservationType;
  google_id?: string;
  microsoft_id?: string;
  notes?: string;
}

export interface CreateCommunityCalendarInput {
  title: string;
  startDate: string;
  endDate: string;
  service: string;
  client: string;
  appartment_id: string;
  project_id: string;
  reservation_type?: ServiceReservationType;
  google_id?: string;
  microsoft_id?: string;
  notes?: string;
}

export const CALENDAR_DATA = `
... on CommunityCalendar {
    ...CommunityCalendarFragment
  }
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
}
`;

export const DELETE_CALENDAR_DATA = `
... on BooleanResult {
    ...BooleanResultFragment
  }
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
}
`;

export const GET_CALENDAR_DATA = `
  id
  title
  startDate
  endDate
  service {
    id 
    name 
    description 
    address 
    images 
    max_reservations 
    time_slot 
    allow_multiple_slot 
    private_service 
    opening_hours{
      day
      ranges{
        from{
          hours
          minutes
        }
        to{
          hours
          minutes
        }
      }
    }
    project_id
  }
  reservation_type
  google_id
  microsoft_id
  notes
  client{
    id
    firstName
  }
  project_id
`;
export const GET_CALENDARS_DATA = `
  id
  title
  startDate
  endDate
  isDeleted
  service {
    id 
    name 
    description 
    address 
    max_reservations 
    time_slot 
    allow_multiple_slot 
    private_service 
    opening_hours{
      day
      ranges{
        from{
          hours
          minutes
        }
        to{
          hours
          minutes
        }
      }
    }
    project_id
  }
  reservation_type
  google_id
  microsoft_id
  notes
  client{
    id
    firstName
  }
  project_id
`;
export const GET_LAST_COMMUNITY_DATA = `
  id
  title
  startDate
  endDate
  service {
    id 
    name 
    description 
    address 
    images
    max_reservations 
    time_slot 
    allow_multiple_slot 
    private_service 
    opening_hours{
      day
      ranges{
        from{
          hours
          minutes
        }
        to{
          hours
          minutes
        }
      }
    }
    project_id
  }
  reservation_type
  google_id
  microsoft_id
  notes
  client{
    id
    firstName
  }
  project_id
`;

export const GET_COMMUNITY_CALENDAR = gql`
  query getCommunityCalendarQuery($id: ID!, $project_id: ID!) {
    getCommunityCalendar(id: $id, project_id: $project_id) {
      ${GET_CALENDAR_DATA}
    }
}
`;

export const GET_COMMUNITY_CALENDARS = gql`
query getCommunityCalendarsQuery($project_id: ID!, $filter: CommunityCalendarFilterInput ) {
  getCommunityCalendars(
    project_id: $project_id
    filter: $filter
  ) {
    ${GET_CALENDARS_DATA}
  }
}
`;
export const GET_LAST_COMMUNITY_CALENDAR = gql`
query getCommunityCalendarsQuery($project_id: ID!, $filter: CommunityCalendarFilterInput ) {
  getCommunityCalendars(
    project_id: $project_id
    filter: $filter
  ) {
    ${GET_LAST_COMMUNITY_DATA}
  }
}
`;

export const GET_COMMUNITY_CALENDARS_BY_PROJECT = gql`
query getCommunityCalendarsByProjectQuery($project_id: ID!) {
  getCommunityCalendarsByProject(project_id: $project_id) {
    ${GET_CALENDAR_DATA}
  }
}
`;

export const CREATE_COMMUNITY_CALENDAR = gql`
mutation createCommunityCalendarMutation($input: CreateCommunityCalendarInput!) {
  createCommunityCalendar(input: $input) { 
    ${CALENDAR_DATA}
  }
  ${COMMUNITY_SERVICE_FRAGMENT}
  ${CLIENT_FRAGMENT}
  ${COMMUNITY_CALENDAR_FRAGMENT}
  ${ERRORS_FRAGMENT}
`;

export const UPDATE_COMMUNITY_CALENDAR = gql`
mutation updateCommunityCalendarMutation($id: ID!, $input: UpdateCommunityCalendarInput!) {
  updateCommunityCalendar(id: $id, input: $input) { 
    ${CALENDAR_DATA}
  }
  ${COMMUNITY_CALENDAR_FRAGMENT}
  ${ERRORS_FRAGMENT}
`;

export const DELETE_COMMUNITY_CALENDAR = gql`
  mutation deleteCommunityCalendarMutation($id: ID!, $project_id: ID!, $appartment_id: ID!) {
    deleteCommunityCalendar(id: $id, project_id: $project_id, appartment_id: $appartment_id){
      ${DELETE_CALENDAR_DATA}
    }
    ${BOOLEAN_RESULT_FRAGMENT}
    ${ERRORS_FRAGMENT}
`;

export const GET_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (
  id: string,
  project_id: string,
): { [key: string]: { [key: string]: string } } => ({
  variables: {
    id,
    project_id,
  },
});

export const GET_COMMUNITY_CALENDARS_BY_PROJECT_DEFAULT_OPTIONS = (
  project_id: string,
): GetCalendarsByProjectOptions => ({
  variables: {
    project_id,
  },
  notifyOnNetworkStatusChange: true,
});

export const GET_COMMUNITY_CALENDARS_DEFAULT_OPTIONS = (
  project_id: string,
  filter: CommunityCalendarFilterInput,
): GetCalendarsOptions => ({
  variables: {
    project_id,
    filter,
  },
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'no-cache',
});

export const CREATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (
  input: CreateCommunityCalendarInput,
): CreateCommunityOptions => ({
  variables: {
    input,
  },
  fetchPolicy: 'no-cache',
});

export const UPDATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (
  id: string,
  input: UpdateCommunityCalendarInput,
): UpdateOptions => ({
  variables: {
    id,
    input,
  },
});

export const DELETE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (
  id: string,
  project_id: string,
  appartment_id: string,
): { [key: string]: { [key: string]: string } } => ({
  variables: {
    id,
    project_id,
    appartment_id,
  },
});
