import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommunityService } from 'types/activity';
import { RootState } from '..';

export interface ActivitiesState {
  data: ICommunityService[];
}

export const initialState: ActivitiesState = {
  data: [],
};

export const activitySlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setActivities: (state, action: PayloadAction<ICommunityService[]>) => {
      return { ...state, data: [...action.payload] };
    },
  },
});

export const { setActivities } = activitySlice.actions;
export const getActivities = (state: RootState) =>
  state.activities.data.slice().sort((a) => (a.open ? -1 : 1));
export const getRandomActivity = (state: RootState) => {
  const openedActivities = state.activities.data
    .slice()
    .filter((service) => service.open);
  return openedActivities[Math.floor(Math.random() * openedActivities.length)];
};
export default activitySlice.reducer;
