import { useMutation } from '@apollo/client';
import Client from 'client';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createAlert } from 'store/slices/alert';
import {
  selectClient,
  setToken,
  unlogUser,
  userToken,
} from 'store/slices/auth';
import { IToken, TokenData } from 'types/auth';

let timeoutRefreshToken: NodeJS.Timeout;

const TokenCountdown = ({
  threshold = '120000',
}: {
  threshold: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(userToken);
  const clientEmail = useAppSelector(selectClient);
  const [refreshToken] = useMutation<TokenData>(Client.REFRESH_TOKEN);

  const expiresIn = token && token.expiresIn;

  useEffect(() => {
    startCountDown(expiresIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const startCountDown = (expiresInValue: string) => {
    const targetTime = +DateTime.fromISO(expiresInValue) - +threshold;
    const baseTimeout = targetTime - Date.now();
    const maxTimeoutValue = 2147483647;
    const timeout =
      baseTimeout > maxTimeoutValue ? maxTimeoutValue : baseTimeout;
    if (timeout > 0) {
      clearTimeout(timeoutRefreshToken);
      timeoutRefreshToken = setTimeout(() => {
        getNewToken(timeout, baseTimeout);
      }, timeout);
    } else if (token && token.accessToken && token.expiresIn) {
      const expireTokenDateValue = DateTime.fromISO(token.expiresIn);
      if (expireTokenDateValue < DateTime.now()) {
        dispatch(unlogUser());
      }
    }
  };

  const getNewToken = async (timeout: number, baseTimeout: number) => {
    const logout = Cookies.get('logout');
    try {
      if (
        logout === 'false' &&
        clientEmail &&
        baseTimeout >= 0 &&
        token.refreshToken
      ) {
        const response = await refreshToken(
          Client.REFRESH_TOKEN_DEFAULT_OPTIONS({
            refreshToken: token && token.refreshToken,
            email: clientEmail && clientEmail.email,
          }),
        );
        if (response.data) {
          const newTokenObject: IToken = response.data.refreshToken.data;
          Cookies.set('jwt', newTokenObject.accessToken, {
            expires: new Date(newTokenObject.expiresIn),
          });
          dispatch(setToken(newTokenObject));
        }
      } else if (baseTimeout < 0) {
        dispatch(unlogUser());
      }
    } catch (error) {
      dispatch(createAlert({ type: 'error', message: `Errore: ${error}` }));
      setTimeout(() => {
        getNewToken(timeout, baseTimeout);
      }, timeout);
    }
  };

  return <></>;
};

export default TokenCountdown;
