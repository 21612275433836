import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import Cookies from 'js-cookie';

const GraphQlClient = (): ApolloClient<NormalizedCacheObject> => {
  const getToken = (): string | undefined => {
    const jwt = Cookies.get('jwt');
    return jwt ? `Bearer ${jwt}` : '';
  };
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_APOLLO_URI });

  const authLink = new ApolloLink((operation, forward) => {
    const apolloOperation = operation;
    const omitTypename = (key: string, value: string) =>
      key === '__typename' ? undefined : value;

    if (apolloOperation.variables && !apolloOperation.getContext().hasUpload) {
      apolloOperation.variables = JSON.parse(
        JSON.stringify(apolloOperation.variables),
        omitTypename,
      );
    }
    apolloOperation.setContext({
      headers: {
        authorization: getToken(),
      },
    });
    return forward(apolloOperation);
  });

  return new ApolloClient<NormalizedCacheObject>({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default GraphQlClient;
