import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { IClient, IToken, IUser } from 'types/auth';
import { RootState } from '..';

export interface AuthState {
  token: IToken;
  user: IUser;
  client: IClient;
  isLogged: boolean;
}

const initialState: AuthState = {
  token: {
    accessToken: '',
    expiresIn: '',
    refreshToken: '',
    tokenType: '',
  },
  user: {
    client: '',
    createdOn: '',
    email: '',
    firstName: '',
    hasGoogleToken: false,
    id: '',
    language: '',
    lastName: '',
    project_ids: [],
    role: '',
    tel: '',
  },
  client: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    avatar: '',
    tel: '',
    city: '',
    birthdate: '',
    cookies: false,
    note: '',
    createdOn: '',
  },
  isLogged: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logUser: (state, action: PayloadAction<IUser>) => {
      Cookies.set('logout', 'false');
      return { ...state, user: action.payload, isLogged: true };
    },
    unlogUser: () => {
      Cookies.remove('jwt');
      Cookies.set('logout', 'true');
      return initialState;
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      return { ...state, client: { ...state.client, avatar: action.payload } };
    },
    setClient: (state, action: PayloadAction<IClient>) => {
      return { ...state, client: action.payload };
    },
    setToken: (state, action: PayloadAction<IToken>) => {
      Cookies.set('jwt', action.payload.accessToken);
      return { ...state, token: action.payload };
    },
    updateToken: (state, action: PayloadAction<IToken>) => ({
      ...state,
      token: {
        ...state.token,
        ...action.payload,
      },
    }),
  },
});

export const {
  logUser,
  unlogUser,
  setToken,
  setClient,
  setAvatar,
  updateToken,
} = authSlice.actions;

export const selectUser = (state: RootState): IUser => state.auth?.user;
export const selectClient = (state: RootState): IClient => state.auth?.client;
export const userCompleteName = (state: RootState): string =>
  `${state.auth.client?.firstName || state.auth.user?.firstName} ${
    state.auth.client?.lastName || state.auth.user?.firstName
  }`;
export const userToken = (state: RootState): IToken => state?.auth?.token;
export const userName = (state: RootState): string =>
  `${state.auth.client?.firstName || state.auth.user?.firstName}`;
export const hasLoggedFirstTime = (state: RootState): boolean =>
  state.auth.client?.cookies;
export const clientID = (state: RootState): string => state.auth.user?.client;
export const userAvatar = (state: RootState): string =>
  state?.auth?.client?.avatar;
export const userIsLogged = (state: RootState): boolean => {
  const hasCookie = Cookies.get('jwt') ?? false;
  return state.auth.isLogged && Boolean(hasCookie);
};

export default authSlice.reducer;
