/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import {
  USER_FRAGMENT,
  CLIENT_FRAGMENT,
  ERRORS_FRAGMENT,
  TOKEN_FRAGMENT,
} from '../fragments';

export const LOGIN_DATA = `
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
  ... on LoginDataExtended {
    user {
      ...UserFragment
    }
    client {
      ...ClientFragment
    }
    token{
      ...TokenFragment
    }
  }
}
`;

const CLIENT_INFO = `
  firstName,
  status
`;

export const TOKEN_DATA = `
  tokenType
  accessToken
  refreshToken
  expiresIn
`;

const CLIENT = `
  id
  email
  tel
  firstName
  lastName
  avatar
  cookies
`;

const USER = `
  id
  firstName
  lastName
  email
  client
  tel
`;
export interface LoginData {
  loginMyLiving: {
    token: {
      accessToken: string;
      refreshToken: string;
      tokenType: string;
      expiresIn: string;
    };
    user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      createdOn: string;
      client: string;
      project_ids: string[];
      language: string;
      role: string;
      tel: string;
      hasGoogleToken: boolean;
      myhome_version: string;
      vendorColor: string;
    };
    client: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: string;
      avatar: string;
      tel: string;
      city: string;
      birthdate: string;
      cookies: boolean;
      note: string;
      createdOn: string;
    };
    error: string;
    message: string;
    __typename: string;
  };
}

export interface ClientData {
  updateClients: {
    id: string;
    email: string;
    tel: string;
    avatar: string;
  };
}

export interface ConfirmUserAndChangePasswordInput {
  project_id: string;
  token: string;
  password: string;
  newPassword: string;
}

export interface ChangePasswordInput {
  project_id: string;
  token: string;
  password: string;
}

export interface TokenOptions {
  variables: {
    refreshTokenInput: RefreshTokenInput;
  };
  fetchPolicy: 'no-cache';
}

export interface CheckClientOptions {
  variables: CheckClientInfoInput;
  fetchPolicy: 'no-cache';
}

export interface AskChangePasswordInput {
  project_id: string;
  email: string;
  template_type: string;
}

export interface RecoveryPasswordOptions {
  variables: AskChangePasswordInput;
  fetchPolicy: 'no-cache';
}

export interface AuthOptions {
  variables: {
    loginByProjectInput: LoginByProjectInput;
  };
  fetchPolicy: 'no-cache';
}

export interface GetClientOptions {
  variables: ClientInput;
  fetchPolicy: 'no-cache';
}

export interface GetUserByClientIdOptions {
  variables: UserByClientIdInput;
  fetchPolicy: 'no-cache';
}

export interface UpdateClientsOptions {
  variables: UpdateClientsInput;
  fetchPolicy: 'no-cache';
}

export interface ChangePasswordOptions {
  variables: ChangePasswordInput;
  fetchPolicy: 'no-cache';
}

export interface ConfirmUserOptions {
  variables: ConfirmUserAndChangePasswordInput;
  fetchPolicy: 'no-cache';
}

export interface RefreshTokenInput {
  email: string;
  refreshToken: string;
}

export interface LoginByProjectInput {
  email: string;
  password: string;
  project_id: string;
}

export interface ClientUserInput {
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  trattamento: boolean;
  profilazione: boolean;
  marketing: boolean;
}

export interface CheckClientInfoInput {
  email: string;
  project_id: string;
}

export interface ClientInput {
  project_id: string;
  id: string;
}
export interface UserByClientIdInput {
  id: string;
  project_id: string;
}

export interface UpdateClientsInput {
  project_id: string;
  id: string;
  clientInput: {
    firstName: string;
    lastName: string;
    tel: string;
    email: string;
    avatar?: string;
  };
}

export const DO_LOGIN = gql`
  mutation loginMyLiving($loginByProjectInput: LoginByProjectInput!){
    loginMyLiving(input: $loginByProjectInput){
      ${LOGIN_DATA}
  }
  ${USER_FRAGMENT}
  ${ERRORS_FRAGMENT}
  ${CLIENT_FRAGMENT}
  ${TOKEN_FRAGMENT}
`;

export const GET_CLIENT_INFO = gql`
  query getClient($project_id :ID!, $id:ID!) {
    getClient(project_id :$project_id, id: $id) {
      ${CLIENT}
    }
  }
`;

export const DO_CONFIRM_USER = gql`
  mutation confirmUserAndChangePassword(
    $project_id: ID!
    $token: String!
    $password: String!
    $newPassword: String!
  ) {
    confirmUserAndChangePassword(
      project_id: $project_id
      token: $token
      password: $password
      newPassword: $newPassword
    )
  }
`;

export const DO_CHANGE_PASSWORD = gql`
  mutation changePassword(
    $project_id: ID!
    $token: String!
    $password: String!
  ) {
    changePassword(project_id: $project_id, token: $token, password: $password)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshTokenInput: RefreshTokenInput!) {
    refreshToken(input: $refreshTokenInput) {
      ${TOKEN_DATA}
    }
  }
`;

export const CHECK_CLIENT_INFO = gql`
  mutation checkClientInfo($project_id: ID!, $email: String!) {
    checkClientInfo(project_id: $project_id, email: $email){
      ${CLIENT_INFO}
    }
  }
`;

export const UPDATE_CLIENT_INFO = gql`
  mutation updateClients($project_id: ID!, $id: ID!, $clientInput: ClientInput!) {
    updateClients(project_id: $project_id, id: $id, input: $clientInput){
      ${CLIENT}
    }
  }
`;
export const GET_USER_BY_CLIENT_ID = gql`
  query getUserByClientId($clientId: ID!, $project_id: ID!) {
    getUserByClientId(clientId: $clientId, project_id: $project_id){
      ${USER}
    }
  }
`;

export const DO_RECOVERY_PASSWORD = gql`
  mutation askChangePassword(
    $project_id: ID!
    $email: String!
    $template_type: String!
  ) {
    askChangePassword(
      project_id: $project_id
      email: $email
      template_type: $template_type
    )
  }
`;

export const GET_CLIENT_INFO_DEFAULT_OPTIONS = (
  clientInput: ClientInput,
): GetClientOptions => ({
  variables: clientInput,
  fetchPolicy: 'no-cache',
});

export const GET_USER_BY_CLIENT_ID_DEFAULT_OPTIONS = (
  userByClientIdInput: UserByClientIdInput,
): GetUserByClientIdOptions => ({
  variables: userByClientIdInput,
  fetchPolicy: 'no-cache',
});

export const UPDATE_CLIENT_INFO_DEFAULT_OPTIONS = (
  updateClientsInput: UpdateClientsInput,
): UpdateClientsOptions => ({
  variables: updateClientsInput,
  fetchPolicy: 'no-cache',
});

export const DO_RECOVERY_PASSWORD_OPTIONS = (
  askChangePasswordInput: AskChangePasswordInput,
): RecoveryPasswordOptions => ({
  variables: askChangePasswordInput,
  fetchPolicy: 'no-cache',
});

export const DO_CHANGE_PASSWORD_OPTIONS = (
  changePasswordInput: ChangePasswordInput,
): ChangePasswordOptions => ({
  variables: changePasswordInput,
  fetchPolicy: 'no-cache',
});

export const CHECK_CLIENT_INFO_OPTIONS = (
  checkClientInfoInput: CheckClientInfoInput,
): CheckClientOptions => ({
  variables: checkClientInfoInput,
  fetchPolicy: 'no-cache',
});

export const REFRESH_TOKEN_DEFAULT_OPTIONS = (
  refreshTokenInput: RefreshTokenInput,
): TokenOptions => ({
  variables: {
    refreshTokenInput,
  },
  fetchPolicy: 'no-cache',
});

export const DO_LOGIN_OPTIONS = (
  loginByProjectInput: LoginByProjectInput,
): AuthOptions => ({
  variables: {
    loginByProjectInput,
  },
  fetchPolicy: 'no-cache',
});

export const DO_CONFIRM_USER_OPTIONS = (
  confirmUserAndChangePasswordInput: ConfirmUserAndChangePasswordInput,
): ConfirmUserOptions => ({
  variables: confirmUserAndChangePasswordInput,
  fetchPolicy: 'no-cache',
});
