import { IonImg } from '@ionic/react';
import React from 'react';

import './splashscreen.scss';

const SplashScreen = React.forwardRef<HTMLDivElement>((_, ref) => (
  <div
    data-testid="splashScreen-container"
    ref={ref}
    style={ref ? { zIndex: 1, opacity: 1, position: 'absolute' } : {}}
    className="splashscreen-page"
  >
    <div className="splashscreen-content">
      <IonImg
        className="splashscreen-content-image"
        src="assets/images/app-name.png"
      />
    </div>
  </div>
));

export default SplashScreen;
