import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

import Client from '../client';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { createAlert } from '../store/slices/alert';
import { projectID } from '../store/slices/info';

interface ChangePasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const useChangePassword = () => {
  const [changePasswordForm, setChangePasswordForm] =
    useState<ChangePasswordForm>({
      newPassword: '',
      confirmPassword: '',
    });
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const queryValues = new URLSearchParams(search);
  const projectId = useAppSelector(projectID);
  const token = queryValues.get('token');

  useEffect(() => {
    if (!token) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MUTATIONS
  const [doChangePassword, { loading }] = useMutation(
    Client.DO_CHANGE_PASSWORD,
  );

  const handleChangePassword = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!token) {
      dispatch(
        createAlert({
          type: 'error',
          message: t('alerts.invalid-token'),
        }),
      );
    }
    if (!changePasswordForm.confirmPassword || !changePasswordForm.newPassword)
      return;
    if (
      changePasswordForm.newPassword === changePasswordForm.confirmPassword &&
      token
    ) {
      const mutationData = {
        password: changePasswordForm.newPassword,
        project_id: projectId,
        token,
      };

      const { data } = await doChangePassword(
        Client.DO_CHANGE_PASSWORD_OPTIONS(mutationData),
      );
      if (data.changePassword) {
        dispatch(
          createAlert({
            type: 'success',
            message: t('password-changed'),
          }),
        );
        history.push('/login');
      }
    } else {
      dispatch(
        createAlert({
          type: 'error',
          message: t('not-match-password'),
        }),
      );
    }
  };

  return {
    changePasswordForm,
    setChangePasswordForm,
    handleChangePassword,
    loading,
  };
};

export default useChangePassword;
