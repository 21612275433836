import React, { useState } from 'react';
import { useTranslation } from '@tecma/i18n';
import { chevronDownOutline } from 'ionicons/icons';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  useIonPopover,
} from '@ionic/react';
import { useAppSelector } from 'store/hooks';
import { sortedAvailableLanguagesSelector } from 'store/slices/info';

import './language-select.scss';
import { PositionAlign, PositionSide } from '@ionic/core';

interface LanguageSelectProps {
  position?: PositionSide;
  alignment?: PositionAlign;
  className?: string;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  position = 'bottom',
  alignment = 'end',
  className,
}) => {
  const { t, i18n } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(false);

  const sortedAvailableLanguages = useAppSelector(
    sortedAvailableLanguagesSelector,
  );

  const [present, dismiss] = useIonPopover(
    <IonContent>
      {sortedAvailableLanguages.map((language) => (
        <IonItem
          key={language}
          button
          onClick={() => {
            i18n.changeLanguage(language);
            setTimeout(() => {
              i18n.changeLanguage(language);
            }, 100);
            dismiss();
          }}
          detail={false}
          className="language-dropdown"
          mode="ios"
        >
          <span className="language-label">
            {t(`language.${language}.label`)}
          </span>
        </IonItem>
      ))}
    </IonContent>,
  );

  return (
    <>
      <IonButton
        className={`language-selector ${className}`}
        fill="clear"
        onClick={(e) => {
          setOpenDropdown(true);
          present({
            event: e as unknown as Event,
            arrow: false,
            alignment,
            side: position,
            cssClass: 'language-selector-menu',
            onDidDismiss: () => {
              setOpenDropdown(false);
            },
          });
        }}
      >
        {t(`language.${i18n.resolvedLanguage}.abbreviation`)}
        <IonIcon
          slot="end"
          className={`selector-icon ${
            openDropdown ? 'rotate' : ''
          } ${position}`}
          icon={chevronDownOutline}
        />
      </IonButton>
    </>
  );
};

export default LanguageSelect;
