import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from '@apollo/client';
import { useState } from 'react';
import { DateTime } from 'luxon';

import Client from 'client';
import { useAppSelector } from 'store/hooks';
import { projectID } from 'store/slices/info';
import { CommunityCalendars } from 'types/activity';
import { CommunityCalendarFilterInput } from 'client/modules/calendar';

const today = new Date();
const useGetBookingList = (filters?: CommunityCalendarFilterInput) => {
  const projectId = useAppSelector(projectID);
  const [refetching, setRefetching] = useState(false);

  const { loading, data, error, refetch } = useQuery<CommunityCalendars>(
    Client.GET_COMMUNITY_CALENDARS,
    Client.GET_COMMUNITY_CALENDARS_DEFAULT_OPTIONS(projectId, {
      startDate: DateTime.fromJSDate(today)
        .set({ day: 1, hour: 0, minute: 0, second: 0 })
        .toISO(),
      endDate: DateTime.fromJSDate(today)
        .set({
          month: today.getMonth() + 2,
          day: 0,
          hour: 24,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toISO(),
      ...filters,
    }),
  );

  const handleRefetching = async (
    variables?: Partial<OperationVariables> | undefined,
  ): Promise<ApolloQueryResult<CommunityCalendars>> => {
    setRefetching(true);
    const result = await refetch(variables);
    setRefetching(false);
    return result;
  };

  return {
    data: data?.getCommunityCalendars || [],
    loading: loading || refetching,
    error,
    refetchBookings: handleRefetching,
  };
};

export default useGetBookingList;
