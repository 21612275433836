import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import Client from 'client';
import { projectID } from 'store/slices/info';
import {
  clientID,
  selectClient,
  userAvatar,
  setAvatar,
} from 'store/slices/auth';
import { ClientData } from 'client/modules/auth';
import { createAlert } from 'store/slices/alert';

const useUserAvatar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientInfo = useAppSelector(selectClient);
  const userAvatarImage = useAppSelector(userAvatar);
  const clientInfoData = {
    project_id: useAppSelector(projectID),
    id: useAppSelector(clientID),
  };
  const [doUpdateAvatar, { loading: loadingUpdate }] = useMutation<ClientData>(
    Client.UPDATE_CLIENT_INFO,
  );

  const updateAvatar = async (fileImage: string) => {
    const updateClientsData = {
      ...clientInfoData,
      clientInput: {
        firstName: clientInfo.firstName,
        lastName: clientInfo.lastName,
        tel: clientInfo.tel,
        email: clientInfo.email,
        avatar: fileImage,
      },
    };
    try {
      const { data } = await doUpdateAvatar(
        Client.UPDATE_CLIENT_INFO_DEFAULT_OPTIONS(updateClientsData),
      );
      if (data) {
        dispatch(setAvatar(data.updateClients.avatar));
      }
    } catch (error) {
      dispatch(
        createAlert({
          type: 'error',
          message: t('errors.generic-w-error', { error }),
        }),
      );
    }
  };

  return {
    avatarImg: userAvatarImage || 'assets/svg/user-placeholder.svg',
    isPlaceholder: !userAvatarImage,
    loading: loadingUpdate,
    updateAvatar,
  };
};

export default useUserAvatar;
