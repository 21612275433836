import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ManifestConfig } from 'types/app';
import { RootState } from '..';

export interface Contact {
  BM: {
    email: {
      bcc: string;
      cc: string;
      to: string;
    };
  };

  CM: {
    email: {
      bcc: string;
      cc: string;
      to: string;
    };
  };
}
export interface MyLivingConfig {
  buildingName: string;
  contacts: Contact;
  languages: string[];
}

export interface InfoState {
  assetKey: string;
  assetLogin: string[];
  assetsUrl: string;
  displayName: string;
  feVendorKey?: string;
  hostKey: string;
  id: string;
  logo: string[];
  manifestConfig: ManifestConfig;
  name: string;
  pageTitles: { [key: string]: string };
  payoff: string;
  pdf: string;
  vendorLogo: string[];
  locale: string;
  style?: { [key: string]: string };
  myLivingConfig: MyLivingConfig;
  disabledLanguages: string[];
  defaultLang: string;
  __typename: string;
}

export const initialState: InfoState = {
  assetKey: '',
  assetLogin: [],
  assetsUrl: '',
  displayName: '',
  feVendorKey: '',
  hostKey: '',
  id: '',
  locale: '',
  logo: [],
  manifestConfig: {
    name: '',
    shortName: '',
    icons: [],
    startUrl: '',
    display: '',
    themeColor: '',
    backgroundColor: '',
    lang: '',
    orientation: '',
  },
  style: {},
  name: '',
  pageTitles: {},
  payoff: '',
  pdf: '',
  vendorLogo: [],
  myLivingConfig: {
    languages: ['en-GB'],
    buildingName: '',
    contacts: {
      BM: {
        email: {
          to: '',
          cc: '',
          bcc: '',
        },
      },
      CM: {
        email: {
          to: '',
          cc: '',
          bcc: '',
        },
      },
    },
  },
  disabledLanguages: [],
  defaultLang: 'en-GB',
  __typename: '',
};

export const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setProjectInfo: (state, action: PayloadAction<InfoState>) => ({
      ...state,
      ...action.payload,
    }),
    setMyLivingConfig: (state, action: PayloadAction<MyLivingConfig>) => ({
      ...state,
      myLivingConfig: action.payload,
    }),
  },
});

export const buildingName = (state: RootState): string =>
  state.info?.myLivingConfig?.buildingName;
export const projectID = (state: RootState): string => state.info.id;
export const localeApp = (state: RootState): string => state.info.locale;
export const projectDisplayName = (state: RootState): string =>
  state.info.displayName;
export const sortedAvailableLanguagesSelector = (state: RootState): string[] =>
  state.info?.myLivingConfig?.languages.slice().sort();
export const { setProjectInfo, setMyLivingConfig } = infoSlice.actions;

export default infoSlice.reducer;
