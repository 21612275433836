/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import { CommunityServiceInput } from 'types/activity';
import { COMMUNITY_SERVICE_FRAGMENT, ERRORS_FRAGMENT } from '../fragments';

export interface UpdateServiceOptions {
  variables: {
    id: string;
    input: CommunityServiceInput;
  };
}

export const SERVICE_DATA = `
  ... on CommunityService {
    ...CommunityServiceFragment
  }
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
`;

export const SERVICE_DATA_REQUEST = `
  id 
  name 
  description 
  address 
  images 
  max_reservations 
  time_slot 
  allow_multiple_slot 
  private_service 
  opening_hours{
    day
    ranges{
      from{
        hours
        minutes
      }
      to{
        hours
        minutes
      }
    }
  }
  project_id
  open
`;

export const GET_SERVICE_BY_PROJECT = gql`
  query getServiceQuery($project_id: ID!, $id: ID!) {
    getService(project_id: $project_id, id: $id) {
      ${SERVICE_DATA_REQUEST}
    }
}`;

export const GET_SERVICES_BY_PROJECT = gql`
  query getServicesByProjectQuery($project_id: ID!) {
    getServicesByProject(project_id: $project_id) {
      ${SERVICE_DATA_REQUEST}
    }
  }
`;
export const GET_CLIENT_LAST_RESERVED_SERVICE = gql`
  query getClientLastReservedServiceQuery($project_id: ID!, $client_id: ID!) {
    getClientLastReservedService(project_id: $project_id, client_id: $client_id) {
      ${SERVICE_DATA}
    }
  }
    ${COMMUNITY_SERVICE_FRAGMENT}
    ${ERRORS_FRAGMENT}
`;

export const CREATE_SERVICE = gql`
  mutation createServiceMutation($input: UpdateCommunityServiceInput! ) {
    createService(input: $input) {
      ${SERVICE_DATA}
    }
  }
    ${COMMUNITY_SERVICE_FRAGMENT}
    ${ERRORS_FRAGMENT}
`;

export const UPDATE_SERVICE = gql`
  mutation updateServiceMutation($id: ID!, $input: UpdateCommunityServiceInput! ) {
    updateService(id: $id, input: $input) {
      ${SERVICE_DATA}
    }
  }
    ${COMMUNITY_SERVICE_FRAGMENT}
    ${ERRORS_FRAGMENT}
`;

export const DELETE_SERVICE = gql`
  mutation deleteServiceMutation($id: ID!, $project_id: ID!) {
    deleteServiceMutation(id: $id, project_id: $project_id)
  }
`;

export const SERVICE_DEFAULT_OPTIONS = (
  id: string,
  project_id: string,
): { [key: string]: { [key: string]: string } } => ({
  variables: {
    id,
    project_id,
  },
});

export const GET_SERVICES_BY_PROJECT_DEFAULT_OPTIONS = (
  project_id: string,
): { [key: string]: { [key: string]: string } } => ({
  variables: {
    project_id,
  },
});

export const GET_CLIENT_LAST_RESERVED_SERVICE_DEFAULT_OPTIONS = (
  project_id: string,
  client_id: string,
): { [key: string]: { [key: string]: string } } => ({
  variables: {
    project_id,
    client_id,
  },
});

export const CREATE_SERVICE_DEFAULT_OPTIONS = (
  input: CommunityServiceInput,
): { [key: string]: { [key: string]: CommunityServiceInput } } => ({
  variables: {
    input,
  },
});

export const UPDATE_SERVICE_DEFAULT_OPTIONS = (
  id: string,
  input: CommunityServiceInput,
): UpdateServiceOptions => ({
  variables: {
    id,
    input,
  },
});
