import {
  DO_LOGIN,
  DO_LOGIN_OPTIONS,
  DO_CONFIRM_USER,
  DO_CONFIRM_USER_OPTIONS,
  CHECK_CLIENT_INFO,
  CHECK_CLIENT_INFO_OPTIONS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  GET_CLIENT_INFO,
  GET_USER_BY_CLIENT_ID,
  GET_USER_BY_CLIENT_ID_DEFAULT_OPTIONS,
  GET_CLIENT_INFO_DEFAULT_OPTIONS,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_DEFAULT_OPTIONS,
  DO_RECOVERY_PASSWORD,
  DO_RECOVERY_PASSWORD_OPTIONS,
  DO_CHANGE_PASSWORD,
  DO_CHANGE_PASSWORD_OPTIONS,
} from './modules/auth';

import {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
} from './modules/info';

import {
  GET_CLIENT_APARTMENTS,
  GET_CLIENT_APARTMENTS_DEFAULT_OPTIONS,
} from './modules/apartments';

import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_DEFAULT_OPTIONS,
  GET_NOTIFICATIONS_LIST,
  GET_NOTIFICATIONS_LIST_DEFAULT_OPTIONS,
  GET_MESSAGES_LIST,
  GET_MESSAGES_LIST_DEFAULT_OPTIONS,
  SET_NOTIFICATION_STATE,
  SET_NOTIFICATION_STATE_DEFAULT_OPTIONS,
  SEND_NOTIFICATION_TO_CM,
  SEND_NOTIFICATION_TO_CM_DEFAULT_OPTIONS,
  GET_PUSH_NOTIFICATIONS,
  GET_PUSH_NOTIFICATIONS_DEFAULT_OPTIONS,
} from './modules/notifications';

import {
  GET_SERVICES_BY_PROJECT,
  GET_SERVICES_BY_PROJECT_DEFAULT_OPTIONS,
  GET_SERVICE_BY_PROJECT,
  GET_CLIENT_LAST_RESERVED_SERVICE,
  GET_CLIENT_LAST_RESERVED_SERVICE_DEFAULT_OPTIONS,
  SERVICE_DEFAULT_OPTIONS,
  CREATE_SERVICE,
  CREATE_SERVICE_DEFAULT_OPTIONS,
} from './modules/services';

import {
  GET_COMMUNITY_CALENDARS,
  GET_COMMUNITY_CALENDAR,
  GET_COMMUNITY_CALENDARS_BY_PROJECT,
  GET_COMMUNITY_CALENDARS_BY_PROJECT_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDARS_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  CREATE_COMMUNITY_CALENDAR,
  GET_LAST_COMMUNITY_CALENDAR,
  CREATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  DELETE_COMMUNITY_CALENDAR,
  DELETE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
} from './modules/calendar';

const Client = {
  DO_LOGIN,
  DO_CONFIRM_USER,
  REFRESH_TOKEN,
  CHECK_CLIENT_INFO,
  GET_PROJECT_INFO,
  UPDATE_CLIENT_INFO,
  GET_CLIENT_INFO,
  DO_RECOVERY_PASSWORD,
  GET_NOTIFICATIONS_LIST,
  GET_MESSAGES_LIST,
  GET_CLIENT_APARTMENTS,
  SEND_NOTIFICATION_TO_CM,
  GET_SERVICES_BY_PROJECT,
  GET_SERVICE_BY_PROJECT,
  CREATE_SERVICE,
  GET_COMMUNITY_CALENDARS,
  GET_COMMUNITY_CALENDAR,
  GET_COMMUNITY_CALENDARS_BY_PROJECT,
  CREATE_COMMUNITY_CALENDAR,
  DELETE_COMMUNITY_CALENDAR,
  SET_NOTIFICATION_STATE,
  GET_LAST_COMMUNITY_CALENDAR,
  GET_USER_BY_CLIENT_ID,
  GET_CLIENT_LAST_RESERVED_SERVICE,
  DO_CHANGE_PASSWORD,
  GET_NOTIFICATION,
  GET_NOTIFICATION_DEFAULT_OPTIONS,
  GET_PUSH_NOTIFICATIONS,
  GET_PUSH_NOTIFICATIONS_DEFAULT_OPTIONS,
  DO_CHANGE_PASSWORD_OPTIONS,
  DO_CONFIRM_USER_OPTIONS,
  GET_CLIENT_LAST_RESERVED_SERVICE_DEFAULT_OPTIONS,
  GET_USER_BY_CLIENT_ID_DEFAULT_OPTIONS,
  SET_NOTIFICATION_STATE_DEFAULT_OPTIONS,
  DELETE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDARS_BY_PROJECT_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDARS_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  CREATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  SERVICE_DEFAULT_OPTIONS,
  GET_SERVICES_BY_PROJECT_DEFAULT_OPTIONS,
  CREATE_SERVICE_DEFAULT_OPTIONS,
  SEND_NOTIFICATION_TO_CM_DEFAULT_OPTIONS,
  GET_CLIENT_APARTMENTS_DEFAULT_OPTIONS,
  GET_MESSAGES_LIST_DEFAULT_OPTIONS,
  GET_NOTIFICATIONS_LIST_DEFAULT_OPTIONS,
  DO_RECOVERY_PASSWORD_OPTIONS,
  GET_CLIENT_INFO_DEFAULT_OPTIONS,
  UPDATE_CLIENT_INFO_DEFAULT_OPTIONS,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  DO_LOGIN_OPTIONS,
  CHECK_CLIENT_INFO_OPTIONS,
};

export default Client;
