import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'types/notification';
import { RootState } from '..';

export interface Segment {
  mainSegment: string;
  subSegment?: string;
}

export interface ServiceWorker {
  initialized: boolean;
  updated: boolean;
  registration: ServiceWorkerRegistration | null;
  serviceWorkerInit: boolean;
  swUpdating: boolean;
}

export interface LoadingState {
  active: boolean;
  text?: string;
}

export interface NewNotificationState {
  state: 'open' | 'close';
  item: Notification | null;
}

export interface AppState {
  segment: Segment;
  serviceWorker: ServiceWorker;
  loadingState: LoadingState;
  notifications: NewNotificationState[];
  __typename: string;
}

export const initialState: AppState = {
  segment: {
    mainSegment: 'foryou',
    subSegment: '',
  },
  loadingState: {
    active: false,
    text: '',
  },
  serviceWorker: {
    initialized: false,
    updated: false,
    registration: null,
    serviceWorkerInit: false,
    swUpdating: false,
  },
  notifications: [],
  __typename: '',
};

export const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setCurrentSegment: (state, action: PayloadAction<Segment>) => ({
      ...state,
      segment: { ...state.segment, ...action.payload },
    }),
    setServiceWorkerInitialized: (state) => ({
      ...state,
      serviceWorker: { ...state.serviceWorker, initialized: true },
    }),
    setServiceWorkeUpdated: (state, action: PayloadAction<boolean>) => ({
      ...state,
      serviceWorker: { ...state.serviceWorker, updated: action.payload },
    }),
    setServiceWorkerReady: (state) => ({
      ...state,
      serviceWorker: { ...state.serviceWorker, serviceWorkerInit: true },
    }),
    setServiceWorkerUpdating: (state) => ({
      ...state,
      serviceWorker: { ...state.serviceWorker, swUpdating: true },
    }),
    setServiceWorkerUpdateComplete: (state) => ({
      ...state,
      serviceWorker: { ...state.serviceWorker, swUpdating: false },
    }),
    setLoadingState: (state, action: PayloadAction<string>) => {
      return { ...state, loadingState: { active: true, text: action.payload } };
    },
    resetLoadingState: (state) => {
      return { ...state, loadingState: initialState.loadingState };
    },
    setNotificationsToOpen: (state, action: PayloadAction<Notification>) => ({
      ...state,
      notifications: [
        ...state.notifications,
        {
          state: 'open',
          item: action.payload,
        },
      ],
    }),
    resetNotificationToOpen: (state) => {
      return {
        ...state,
        notifications: [],
      };
    },
    setServiceWorkerRegistration: (
      state,
      action: PayloadAction<ServiceWorkerRegistration>,
    ) => ({
      ...state,
      serviceWorker: {
        ...state.serviceWorker,
        updated: true,
        registration: action.payload,
      },
    }),
  },
});

export const currentSegment = (state: RootState): Segment => state.app.segment;
export const newNotificationToOpen = (state: RootState): NewNotificationState =>
  state.app?.notifications?.[0];
export const isServiceWorkerUpdate = (state: RootState): boolean =>
  state.app.serviceWorker.updated;
export const serviceWorkerRegistration = (
  state: RootState,
): ServiceWorkerRegistration | null => state.app.serviceWorker.registration;
export const getLoadingState = (state: RootState): LoadingState =>
  state.app.loadingState;

export const {
  setCurrentSegment,
  setServiceWorkerInitialized,
  setServiceWorkeUpdated,
  setServiceWorkerRegistration,
  setServiceWorkerReady,
  setServiceWorkerUpdating,
  setServiceWorkerUpdateComplete,
  setNotificationsToOpen,
  resetNotificationToOpen,
  setLoadingState,
  resetLoadingState,
} = appSlice.actions;

export default appSlice.reducer;
