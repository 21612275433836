import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

import Client from 'client';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { createAlert } from 'store/slices/alert';

const useRecoveryPassword = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector((state) => state.info.id);
  const [doCheckClient, checkClientData] = useMutation(
    Client.CHECK_CLIENT_INFO,
  );
  const [doRecoveryPassword, recoveryData] = useMutation(
    Client.DO_RECOVERY_PASSWORD,
  );

  const handleRecoveryPassword = async (email: string) => {
    const mutationCheckData = {
      project_id: projectId,
      email,
    };
    const mutationData = {
      email,
      template_type: 'changePasswordMyLiving',
      project_id: projectId,
    };
    const { data: checkClientResp } = await doCheckClient(
      Client.CHECK_CLIENT_INFO_OPTIONS(mutationCheckData),
    );
    if (checkClientResp.checkClientInfo.status !== 'userAlreadyRegistered') {
      dispatch(
        createAlert({
          type: 'success',
          message: t('alerts.sent-recovery-email'),
        }),
      );
    } else if (
      checkClientResp.checkClientInfo.status === 'userAlreadyRegistered'
    ) {
      const { data: resp } = await doRecoveryPassword(
        Client.DO_RECOVERY_PASSWORD_OPTIONS(mutationData),
      );
      if (resp && resp.askChangePassword.success) {
        dispatch(
          createAlert({
            type: 'success',
            message: t('alerts.sent-recovery-email'),
          }),
        );
      }
    }
  };

  return {
    loading: checkClientData.loading || recoveryData.loading,
    recoveryPassword: handleRecoveryPassword,
  };
};

export default useRecoveryPassword;
