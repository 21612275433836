import { useImperativeQuery } from 'hooks';
import React, { ReactElement } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  useLocation,
  useHistory,
} from 'react-router-dom';

import Client from 'client';
import SplashScreen from 'components/SplashScreen';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logUser, setClient, setToken, userIsLogged } from 'store/slices/auth';
import { projectID } from 'store/slices/info';
import { ClientData, UserData } from 'types/auth';
import { INavigationRoute } from './navigation';

export type PrivateRouteProps = RouteProps & INavigationRoute;

const PrivateRoute: React.FC<PrivateRouteProps> = React.memo(
  ({ component: Component, ...rest }): ReactElement => {
    const isAuthenticated = useAppSelector(userIsLogged);
    const { path } = rest;
    const getUser = useImperativeQuery<UserData>(Client.GET_USER_BY_CLIENT_ID);
    const getClientInfoQuery = useImperativeQuery<ClientData>(
      Client.GET_CLIENT_INFO,
    );
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const projectId = useAppSelector(projectID);
    const queryValues = new URLSearchParams(search);
    const clientId = queryValues.get('clientId');
    const refreshToken = queryValues.get('refreshToken');
    const jwt = queryValues.get('jwt');
    const expiresIn = queryValues.get('expiresIn');
    if (path === '/' && clientId && jwt && refreshToken) {
      if (expiresIn) {
        const loginFromMyHome = async () => {
          const userData = await getUser({
            clientId,
            project_id: projectId,
          });
          if (userData?.data) {
            const clientData = await getClientInfoQuery({
              project_id: projectId,
              id: clientId,
            });
            if (clientData?.data) {
              dispatch(logUser(userData.data.getUserByClientId[0]));
              dispatch(setClient(clientData.data.getClient));
              const redirectURL = clientData.data.getClient.cookies
                ? '/'
                : '/welcome';
              history.replace(redirectURL);
            }
          }
        };
        dispatch(
          setToken({
            accessToken: jwt,
            refreshToken,
            tokenType: 'Bearer',
            expiresIn,
          }),
        );
        loginFromMyHome();
      }
      return <SplashScreen />;
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
  },
);

export default PrivateRoute;
