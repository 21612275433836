/* eslint-disable no-underscore-dangle */
import { NetworkStatus, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useAppSelector } from 'store/hooks';
import { clientID } from 'store/slices/auth';
import { projectID } from 'store/slices/info';
import { ICommunityService, LastServiceData } from 'types/activity';
import Client from '../client';

const useLastService = () => {
  const [service, setService] = useState<ICommunityService>();
  const clientId = useAppSelector(clientID);
  const projectId = useAppSelector(projectID);
  const allActivities = useAppSelector((state) => state.activities.data);
  const { loading, data, error, refetch, networkStatus } =
    useQuery<LastServiceData>(Client.GET_CLIENT_LAST_RESERVED_SERVICE, {
      ...Client.GET_CLIENT_LAST_RESERVED_SERVICE_DEFAULT_OPTIONS(
        projectId,
        clientId,
      ),
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && networkStatus !== NetworkStatus.refetch && data) {
      if (
        !data.getClientLastReservedService.error &&
        data.getClientLastReservedService.open
      ) {
        setService(data.getClientLastReservedService);
      } else {
        const randomActivity = allActivities
          .slice()
          .filter((item) => item.open);
        setService(
          randomActivity[Math.floor(Math.random() * randomActivity.length)],
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  return {
    data: service,
    loading: loading || networkStatus === NetworkStatus.refetch,
    error,
  };
};

export default useLastService;
