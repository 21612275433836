import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { detectUrl, loadScssFromDB } from 'utils';
import { useAppDispatch } from 'store/hooks';
import Client from 'client';
import {
  InfoState,
  MyLivingConfig,
  setMyLivingConfig,
  setProjectInfo,
} from 'store/slices/info';
import { useTranslation, loadResources } from '@tecma/i18n';
import ProjectAPI from '../client/api/Project';

const useProjectInfo = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { data, called, error, refetch } = useQuery<{
    getProjectInfoByHost: InfoState;
  }>(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(detectUrl()),
  );

  useEffect(() => {
    if (data && called) {
      const project = data.getProjectInfoByHost;
      i18n.changeLanguage(project.defaultLang);
      dispatch(setProjectInfo(project));
      loadScssFromDB(project);
      ProjectAPI.getMyLivingConfigByProject(project.id).then(
        (response: Response) => {
          if (response.ok) {
            response.json().then((myLivingConfig: MyLivingConfig) => {
              dispatch(setMyLivingConfig(myLivingConfig));
              loadResources({
                id: project.id,
                displayName: project.displayName,
                languages: myLivingConfig.languages,
              }).then(() => {
                setLoading(false);
              });
            });
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, called]);

  return { data, loading, error, refetch };
};

export default useProjectInfo;
