import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Apartment } from 'types/apartment';
import { RootState } from '..';

export interface ApartmentState {
  data: Apartment[];
}

export const initialState: ApartmentState = {
  data: [],
};

export const apartmentsSlice = createSlice({
  name: 'apartments',
  initialState,
  reducers: {
    setApartments: (state, action: PayloadAction<Apartment[]>) => ({
      ...state,
      data: action.payload,
    }),
  },
});
export const apartmentID = (state: RootState) => state.apartments.data[0]?.id;
export const buildingID = (state: RootState) =>
  state.apartments.data[0]?.sides[0]?.id;
export const isLoadingApartments = (state: RootState) =>
  !state.apartments.data.length;
export const siderbarApartmentInfo = (state: RootState) => ({
  aptName: state.apartments.data[0]?.name,
  floor: state.apartments.data[0]?.floor,
});
export const hasBuildingCoordsSelector = (state: RootState): boolean =>
  Boolean(state.apartments?.data?.[0]?.sides?.[0]?.building?.geo?.lat);
export const buildingCoords = (state: RootState): string =>
  `${state.apartments?.data?.[0]?.sides?.[0]?.building?.geo?.lat},${state.apartments?.data?.[0]?.sides?.[0]?.building?.geo?.lon}`;
export const projectID = (state: RootState): string => state.info.id;
export const getApartments = (state: RootState) => state.apartments.data;

export const { setApartments } = apartmentsSlice.actions;

export default apartmentsSlice.reducer;
