/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, NOTIFICATION_FRAGMENT } from 'client/fragments';

import {
  NotificationClientNoticeType,
  NotificationType,
  NotificationPriority,
  NotificationClientBreakdown,
  AttachmentInput,
  NotificationState,
  NotificationCreationUser,
} from '../../types/notification';

export interface NotificationsInput {
  project_id: string;
  client: string | null;
  page?: number;
  perPage?: number;
  archived?: boolean;
}
export interface MessagesInput {
  project_id: string;
  client: string | null;
  page?: number;
  perPage?: number;
  showExpired?: boolean;
}
export interface NotificationInput {
  project_id: string;
  notification_id: string;
}
export interface SetNotificationsInput {
  id: string;
  client_id?: string;
  project_id: string;
  state: NotificationState;
  archive?: boolean;
  from: NotificationCreationUser;
}
export interface SendNotificationsInput {
  project_id: string;
  client_id: string;
  input: NotifyCMInput;
}

export interface GetPushNotificationsInput {
  project_id: string;
  client: string;
}
export interface NotifyCMInput {
  subject: string;
  message: string;
  type: NotificationType[];
  notice: NotificationClientNoticeType;
  appartment: string;
  building: string;
  priority?: NotificationPriority;
  breakdown?: NotificationClientBreakdown;
  buildingOnly?: boolean;
  attachments?: AttachmentInput[];
}

export interface GetNotificationsOptions {
  variables: NotificationsInput;
  fetchPolicy: 'no-cache';
  notifyOnNetworkStatusChange: boolean;
}
export interface GetMessagesOptions {
  variables: MessagesInput;
  fetchPolicy: 'no-cache';
  notifyOnNetworkStatusChange: boolean;
}

export interface GetNotificationOptions {
  variables: NotificationInput;
  fetchPolicy: 'no-cache';
}

export interface GetPushNotificationsOptions {
  variables: GetPushNotificationsInput;
  fetchPolicy: 'cache-and-network';
}
export interface SetNotificationsOptions {
  variables: SetNotificationsInput;
  fetchPolicy: 'no-cache';
}
export interface SendNotificationsOptions {
  variables: SendNotificationsInput;
  fetchPolicy: 'no-cache';
}

const NOTIFICATIONS_LIST_DATA_TO_RETRIEVE = `
  data{
    id
    subject
    message
    type
    notice
    priority
    breakdown
    state
    client
    read
    buildingOnly
    validated
    archived
    cancelled
    createdOn
    createdBy
    updatedOn
    deletedOn
    attachments{
      type
      base64
    }
    appartment
    building
    project_id
  }
  count
`;
const MESSAGES_LIST_DATA_TO_RETRIEVE = `
  data{
    id
    subject
    message
    type
    notice
    priority
    breakdown
    state
    client
    read
    buildingOnly
    validated
    archived
    cancelled
    createdOn
    createdBy
    updatedOn
    deletedOn
    attachments{
      type
      base64
    }
    appartment
    building
    project_id
    state
  }
  count
`;

export const NOTIFICATION_DATA = `
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
  ...NotificationFragment
`;

const PUSH_NOTIFICATIONS_DATA = `
  id
  subject
  message
  breakdown
  status
  notice
  isMessage
  isReply
  createdOn
`;

export const SET_NOTIFICATION_DATA = `
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
  ... on GenericResponse {
    done
  }
`;

export const SEND_NOTIFICATION_DATA = `
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
  ...NotificationFragment
`;

export const GET_NOTIFICATIONS_LIST = gql`
  query getClientNotifications ($project_id: ID!, $client: ID!, $page: Int, $perPage: Int, $archived: Boolean) {
    getClientNotifications (project_id: $project_id, client: $client, page: $page, perPage: $perPage, archived: $archived) {
    ${NOTIFICATIONS_LIST_DATA_TO_RETRIEVE}
  }
}`;
export const GET_NOTIFICATION = gql`
  query getNotification ($project_id: ID!, $notification_id: ID!) {
    getNotification (project_id: $project_id, notification_id: $notification_id) {
    ${NOTIFICATION_DATA}
  }
}
  ${NOTIFICATION_FRAGMENT}
  ${ERRORS_FRAGMENT}
`;

export const SEND_NOTIFICATION_TO_CM = gql`
  mutation sendNotificationToCM ($project_id: ID!, $client_id: ID!, $input: NotifyCMInput!) {
    sendNotificationToCM (project_id: $project_id, client_id: $client_id, input: $input) {
    ${SEND_NOTIFICATION_DATA}
    }
  }
  ${NOTIFICATION_FRAGMENT}
  ${ERRORS_FRAGMENT}
`;

export const SET_NOTIFICATION_STATE = gql`
  mutation setNotificationState ($id: ID!, $client_id: ID, $project_id: ID!, $state: NotificationState!, $archive: Boolean, $from: NotificationCreationUser!) {
    setNotificationState (id: $id, client_id: $client_id, project_id: $project_id, state: $state, archive: $archive, from: $from) {
    ${SET_NOTIFICATION_DATA}
    }
  }
  ${ERRORS_FRAGMENT}
`;

export const GET_MESSAGES_LIST = gql`
  query getClientMessages ($project_id: ID!, $client: ID!, $page: Int, $perPage: Int, $showExpired: Boolean) {
    getClientMessages (project_id: $project_id, client: $client, page: $page, perPage: $perPage, showExpired: $showExpired) {
    ${MESSAGES_LIST_DATA_TO_RETRIEVE}
  }
}`;

export const GET_PUSH_NOTIFICATIONS = gql`
  query getAlerts($project_id: ID!, $client: ID) {
    getAlerts(project_id: $project_id, client: $client){
      ${PUSH_NOTIFICATIONS_DATA}
  }
}`;

export const SET_NOTIFICATION_STATE_DEFAULT_OPTIONS = (
  setNotificationsInput: SetNotificationsInput,
): SetNotificationsOptions => ({
  variables: setNotificationsInput,
  fetchPolicy: 'no-cache',
});

export const GET_NOTIFICATIONS_LIST_DEFAULT_OPTIONS = (
  notificationsInput: NotificationsInput,
): GetNotificationsOptions => ({
  variables: notificationsInput,
  fetchPolicy: 'no-cache',
  notifyOnNetworkStatusChange: true,
});

export const GET_NOTIFICATION_DEFAULT_OPTIONS = (
  notificationInput: NotificationInput,
): GetNotificationOptions => ({
  variables: notificationInput,
  fetchPolicy: 'no-cache',
});

export const SEND_NOTIFICATION_TO_CM_DEFAULT_OPTIONS = (
  sendNotificationsInput: SendNotificationsInput,
): SendNotificationsOptions => ({
  variables: sendNotificationsInput,
  fetchPolicy: 'no-cache',
});

export const GET_MESSAGES_LIST_DEFAULT_OPTIONS = (
  messagesInput: MessagesInput,
): GetMessagesOptions => ({
  variables: messagesInput,
  fetchPolicy: 'no-cache',
  notifyOnNetworkStatusChange: true,
});

export const GET_PUSH_NOTIFICATIONS_DEFAULT_OPTIONS = (
  getPushNotificationsInput: GetPushNotificationsInput,
): GetPushNotificationsOptions => {
  const options: GetPushNotificationsOptions = {
    variables: getPushNotificationsInput,
    fetchPolicy: 'cache-and-network',
  };
  return options;
};
