import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { Segment, setCurrentSegment } from 'store/slices/app';
import { ExtraInfoService } from 'types/activity';
import { MessageBoard } from 'types/message';
import { SidebarLink } from 'types/app';
import { NotificationsStatusDetail } from 'types/notification';

export const ENGLISH_DAYS = [
  'Sunday',
  'Monday',
  'Thuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getPermissionsApp = () => [
  {
    icon: 'cookie',
    title: 'cookie',
    image: 'cookie.jpg',
    content: 'cookie-content',
  },
];

export const generateAppLinks = (
  projectName: string,
  dispatch: ThunkDispatch<Segment, void, AnyAction> | Dispatch<AnyAction>,
): SidebarLink[] => [
  {
    title: 'for-you',
    segmentLink: 'foryou',
    icon: 'assets/svg/for-you.svg',
    goToSegment: () => {
      dispatch(setCurrentSegment({ mainSegment: 'foryou', subSegment: '' }));
    },
  },
  {
    title: 'life-style',
    segmentLink: 'myLifeStyle',
    subSegmentLink: 'indoor',
    icon: 'assets/svg/hearth.svg',
    goToSegment: () => {
      dispatch(
        setCurrentSegment({ mainSegment: 'myLifeStyle', subSegment: 'indoor' }),
      );
    },
  },
  {
    title: 'notices',
    segmentLink: 'notices',
    icon: 'assets/svg/notice-icon.svg',
    goToSegment: () => {
      dispatch(setCurrentSegment({ mainSegment: 'notices', subSegment: '' }));
    },
  },
  {
    title: 'my-requests',
    segmentLink: 'myRequest',
    icon: 'assets/svg/star-icon.svg',
    goToSegment: () => {
      dispatch(setCurrentSegment({ mainSegment: 'myRequest', subSegment: '' }));
    },
  },
];

interface WeatherCodes {
  [key: string]: {
    day: WeatherDetail;
    night: WeatherDetail;
  };
}
interface WeatherDetail {
  conditionText: string;
  icon: string;
}

export type CommunicationAndNotificationKey =
  | keyof typeof COMMUNICATIONS_ITEMS
  | keyof typeof NEW_NOTIFICATION_REQUEST_ITEMS;

export type CommunicationAndNotification = {
  requestType: string;
  icon: string;
  color: string;
};

export const COMMUNICATIONS_ITEMS: {
  [key: string]: CommunicationAndNotification;
} = {
  WATER_HEATER: {
    requestType: 'Caldaia',
    icon: 'boiler',
    color: '#EDDBC5',
  },
  WATER: {
    requestType: 'Acqua',
    icon: 'water',
    color: '#BED6D9',
  },
  PACKAGE: {
    requestType: 'Pacco',
    icon: 'parcel',
    color: '#E1DBE9',
  },
  FIRE: {
    requestType: 'Incendio',
    icon: 'fire',
    color: '#F5B5B5',
  },
  COVID: {
    requestType: 'Covid',
    icon: 'covid',
    color: '#EED2CC',
  },
  LIGHT: {
    requestType: 'Luce',
    icon: 'electricity',
    color: '#F8EABA',
  },
  COMMUNICATIONS: {
    requestType: 'Comunicazioni',
    icon: 'comunication',
    color: '#E7EDF3',
  },
  GREEN: {
    requestType: 'Green',
    icon: 'green',
    color: '#C5DAC8',
  },
  GAS: {
    requestType: 'Gas',
    icon: 'gas',
    color: '#D7D4D8',
  },
  OTHER: {
    requestType: 'Altro',
    icon: 'no-classified',
    color: '#D8E0E7',
  },
};

export const NEW_NOTIFICATION_REQUEST_ITEMS: {
  [key: string]: CommunicationAndNotification;
} = {
  FORNITURE: {
    requestType: 'furniture',
    icon: 'sofa',
    color: '#E4EBEC',
  },
  CONDITIONING: {
    requestType: 'heating',
    icon: 'thermometer',
    color: '#F1EEF5',
  },
  ROLLER_SHUTTER: {
    requestType: 'roller-shutter',
    icon: 'roller-shutter',
    color: '#EEF5EF',
  },
  WINDOW: {
    requestType: 'window',
    icon: 'window',
    color: '#F5F3EE',
  },
  BATHROOM: {
    requestType: 'bathroom',
    icon: 'toilet',
    color: '#F5EEEE',
  },
  OTHER: {
    requestType: 'other',
    icon: 'other-icon',
    color: '#F5F3EE',
  },
};

// SEE ALL POSSIBLE WEATHER CODES https://www.weatherapi.com/docs/

export const WEATHER_CONDITIONS_CODES: WeatherCodes = {
  '1000': {
    day: {
      conditionText: 'Soleggiato',
      icon: 'assets/svg/weather-icons/clear-day.svg',
    },
    night: {
      conditionText: 'Sereno',
      icon: 'assets/svg/weather-icons/clear-night.svg',
    },
  },
  '1003': {
    day: {
      conditionText: 'Poco nuvoloso',
      icon: 'assets/svg/weather-icons/few-clouds-day.svg',
    },
    night: {
      conditionText: 'Poco nuovoloso',
      icon: 'assets/svg/weather-icons/few-clouds-night.svg',
    },
  },
  '1006': {
    day: {
      conditionText: 'Nuvoloso',
      icon: 'assets/svg/weather-icons/clouds.svg',
    },
    night: {
      conditionText: 'Nuvoloso',
      icon: 'assets/svg/weather-icons/clouds.svg',
    },
  },
  '1009': {
    day: {
      conditionText: 'Coperto',
      icon: 'assets/svg/weather-icons/clouds.svg',
    },
    night: {
      conditionText: 'Coperto',
      icon: 'assets/svg/weather-icons/clouds.svg',
    },
  },
  '1030': {
    day: {
      conditionText: 'Nebbia',
      icon: 'assets/svg/weather-icons/fog.svg',
    },
    night: {
      conditionText: 'Nebbia',
      icon: 'assets/svg/weather-icons/fog.svg',
    },
  },
  '1063': {
    day: {
      conditionText: 'Possibile pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Possibile pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1066': {
    day: {
      conditionText: 'Possibile neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Possibile neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1069': {
    day: {
      conditionText: 'Possibile nevischio',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Possibile nevischio',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1072': {
    day: {
      conditionText: 'Possibile pioggia gelata',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Possibile pioggia gelata',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1087': {
    day: {
      conditionText: 'Possibili tuoni',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
    night: {
      conditionText: 'Possibili tuoni',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
  },
  '1114': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1117': {
    day: {
      conditionText: 'Bufera di neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Bufera di neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1135': {
    day: {
      conditionText: 'Nebbia',
      icon: 'assets/svg/weather-icons/fog.svg',
    },
    night: {
      conditionText: 'Nebbia',
      icon: 'assets/svg/weather-icons/fog.svg',
    },
  },
  '1147': {
    day: {
      conditionText: 'Nebbia gelata',
      icon: 'assets/svg/weather-icons/fog.svg',
    },
    night: {
      conditionText: 'Nebbia gelata',
      icon: 'assets/svg/weather-icons/fog.svg',
    },
  },
  '1150': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1153': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1168': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1171': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1180': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1183': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1186': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1189': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1192': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1195': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1198': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1201': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1204': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1207': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1210': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1213': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1216': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1219': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1222': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1225': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1237': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1240': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1243': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1246': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1249': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1252': {
    day: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-day.svg',
    },
    night: {
      conditionText: 'Pioggia',
      icon: 'assets/svg/weather-icons/rain-night.svg',
    },
  },
  '1255': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1258': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1261': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1264': {
    day: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
    night: {
      conditionText: 'Neve',
      icon: 'assets/svg/weather-icons/snow.svg',
    },
  },
  '1273': {
    day: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
    night: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
  },
  '1276': {
    day: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
    night: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
  },
  '1279': {
    day: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
    night: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
  },
  '1282': {
    day: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
    night: {
      conditionText: 'Temporale',
      icon: 'assets/svg/weather-icons/thunderstorm.svg',
    },
  },
};

export const NOTIFICATION_STATUS: NotificationsStatusDetail = {
  SENT: {
    message: 'sent',
    icon: 'icon-mail.svg',
  },
  READ: {
    message: 'read',
    icon: 'icon-mail.svg',
  },
  IN_PROGRESS: {
    message: 'in-progress',
    icon: 'icon-mail.svg',
  },
  REFUSED: {
    message: 'refused',
    icon: 'icon-cross.svg',
  },
  CANCELLED: {
    message: 'cancelled',
    icon: 'icon-off.svg',
  },
  CLOSED: {
    message: 'completed',
    icon: 'icon-ok.svg',
  },
};

export const EXTRA_INFO_SERVICES: ExtraInfoService = {
  gym: {
    icon: 'gym-icon.svg',
    color: '#CAC1DA',
    steps: [
      {
        title: 'services.choose-when',
        stepText: 'services.choose-day',
        icon: 'step-calendar.svg',
        description: 'services.day-calendar',
      },
      {
        title: 'services.choose-time-title',
        stepText: 'services.choose-time',
        icon: 'step-clock.svg',
        description: 'services.preferred-time',
      },
    ],
  },
  lavanderia: {
    icon: 'laundry-icon.svg',
    color: '#F2E6C6',
    steps: [
      {
        title: 'services.choose-when',
        stepText: 'services.choose-day',
        icon: 'step-calendar.svg',
        description: 'services.day-calendar',
      },
      {
        title: 'services.choose-time-title',
        stepText: 'services.choose-time',
        icon: 'step-clock.svg',
        description: "Indica l'orario che preferisci ",
      },
    ],
  },
  'temporary apartment': {
    icon: 'sofa-icon.svg',
    color: '#CAD3B7',
    steps: [
      {
        title: 'services.choose-when',
        stepText: 'services.choose-day',
        icon: 'step-calendar.svg',
        description: 'services.day-calendar',
      },
    ],
  },
  'spazio coworking': {
    icon: 'co-working-icon.svg',
    color: '#B5CDD2',
    steps: [
      {
        title: 'services.choose-when',
        stepText: 'services.choose-day',
        icon: 'step-calendar.svg',
        description: 'services.day-calendar',
      },
    ],
  },
};

export const forYouBoards = (): MessageBoard[] => [
  {
    title: 'life-style',
    segment: { mainSegment: 'myLifeStyle', subSegment: 'indoor' },
    subTitle: 'discover-activities',
    description: 'on-project',
    image: 'lifestyle-card',
    color: '#BBCECA88',
  },
  {
    title: 'notices',
    segment: { mainSegment: 'notices', subSegment: '' },
    subTitle: 'discover-notices',
    description: 'in-project',
    image: 'comunication-card',
    color: '#E5DEF288',
  },
];
