const NOTIFICATION_ERRORS: { [key: string]: string } = {
  GENERIC_ERROR: 'generic',
  APARTMENT_NOT_FOUND: 'no-apartment',
  VALIDATION_ERROR: 'validation',
  NOTIFICATION_NOT_FOUND: 'no-notification',
  UNAUTHORIZED: 'unauthorized',
};

export interface ClientError {
  error?: string;
  message?: string;
  __typename?: string;
}

export default NOTIFICATION_ERRORS;
