/* eslint-disable @typescript-eslint/no-explicit-any */
/* BAD TYPING FROM FRAMEWORK */
import React from 'react';
import { IonAlert } from '@ionic/react';
import './action-alert.scss';
import { AlertOptions, OverlayEventDetail } from '@ionic/core';

interface ActionAlertProps extends AlertOptions {
  isOpen: boolean;
  onWillDismiss?: (event: CustomEvent<OverlayEventDetail<any>>) => void;
}

const ActionAlert: React.FC<ActionAlertProps> = (props) => {
  return <IonAlert {...props} cssClass="action-alert" />;
};

export default ActionAlert;
