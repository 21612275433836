import React, { useRef, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { menuController } from '@ionic/core/components';
import { useHistory } from 'react-router-dom';

import Avatar from 'components/Avatar';
import UserInfo from 'components/UserInfo';
import ActionAlert from 'components/ActionAlert';
import MyBookingCalendar from 'components/MyBookingCalendar';
import LanguageSelect from 'components/LanguageSelect';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { unlogUser, userName } from 'store/slices/auth';
import { generateAppLinks } from 'constant';
import { SidebarLink } from 'types/app';
import {
  currentSegment,
  resetLoadingState,
  setLoadingState,
} from 'store/slices/app';
import { buildingName, projectDisplayName } from 'store/slices/info';
import { chevronBackOutline } from 'ionicons/icons';
import { useTranslation } from '@tecma/i18n';
import './sidebar.scss';

export interface SidebarProps {
  swipeGesture: boolean;
}

const Sidebar: React.FC<SidebarProps> = React.memo(({ swipeGesture }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showActionAlert, setShowActionAlert] = useState(false);
  const history = useHistory();
  const [openAgenda, setOpenAgenda] = useState(false);
  const clientName = useAppSelector(userName);
  const currentSegmentStored = useAppSelector(currentSegment);
  const buildingNameValue = useAppSelector(buildingName);
  const appLinks = generateAppLinks(buildingNameValue, dispatch);
  const sidebarRef = useRef<HTMLIonMenuElement>(null);
  const projectName = useAppSelector(projectDisplayName);
  const projectIcon = `${
    process.env.REACT_APP_BUCKET_BASEURL
  }/initiatives/${encodeURI(projectName)}/global/img/logo-icon.svg`;

  const isActiveLink = (appPage: SidebarLink) => {
    const isSubsegmentLink = !!appPage.subSegmentLink;
    return isSubsegmentLink
      ? currentSegmentStored.subSegment === appPage.subSegmentLink
      : currentSegmentStored.mainSegment === appPage.segmentLink;
  };

  const closeSidebar = () => {
    sidebarRef.current?.close(true);
  };

  const handleOnCLick = (sidebarLink: SidebarLink) => {
    sidebarRef.current?.close(true);
    sidebarLink.goToSegment(dispatch);
  };

  return (
    <IonMenu
      data-testid="sidebar-container"
      ref={sidebarRef}
      menuId="main"
      swipeGesture={swipeGesture}
      disabled={!swipeGesture}
      contentId="main"
      maxEdgeStart={10}
      type="overlay"
    >
      <IonContent>
        <div className="sidebar-top-background">
          <div className="sidebar-project-logo">
            <IonIcon className="sidebar-project-logo-icon" src={projectIcon} />
          </div>
          <IonHeader className="ion-no-border sidebar-header">
            <IonToolbar className="sidebar-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  mode="ios"
                  className="sidebar-close-button"
                  onClick={closeSidebar}
                >
                  <IonIcon icon={chevronBackOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>
                <p className="sidebar-title">{t('profile')}</p>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <Avatar />
        </div>
        <div className="sidebar-content">
          <IonGrid className="sidebar-custom-grid">
            <IonRow className="ion-justify-content-center sidebar-custom-row">
              <IonCol className="ion-align-self-start ion-no-padding" size="12">
                <UserInfo />
                <IonList className="sidebar-link-list" id="inbox-list">
                  {appLinks.map((appPage) => {
                    return (
                      <IonItem
                        key={appPage.title}
                        routerDirection="forward"
                        lines="none"
                        detail={false}
                        onClick={() => {
                          handleOnCLick(appPage);
                        }}
                        className={`sidebar-link-item ${
                          isActiveLink(appPage) ? 'selected' : ''
                        }`}
                        {...appPage}
                      >
                        <IonLabel>{t(appPage.title)}</IonLabel>
                      </IonItem>
                    );
                  })}
                  <IonItem lines="none" />
                  <IonItem
                    lines="none"
                    detail={false}
                    className="sidebar-link-item agenda-link"
                    onClick={() => {
                      setOpenAgenda((agendaOpen) => !agendaOpen);
                    }}
                  >
                    <IonIcon slot="end" src="assets/svg/calendar-icon.svg" />
                    <IonLabel>{t('agenda')}</IonLabel>
                  </IonItem>
                  <IonItem
                    lines="none"
                    detail={false}
                    className="sidebar-link-item"
                    onClick={() => {
                      setShowActionAlert((value) => !value);
                    }}
                  >
                    <IonLabel>{t('logout')}</IonLabel>
                  </IonItem>
                </IonList>
              </IonCol>
              <IonCol size="4" className="sidebar-language">
                <LanguageSelect
                  position="top"
                  alignment="start"
                  className="sidebar"
                />
              </IonCol>
              <IonCol size="4" className="sidebar-footer">
                <div className="sidebar-footer-icon-container">
                  <IonText className="sidebar-footer-version">
                    v. {process.env.REACT_APP_VERSION}
                  </IonText>
                </div>
              </IonCol>
              <IonCol size="4" />
            </IonRow>
          </IonGrid>
        </div>

        <IonModal
          isOpen={openAgenda}
          canDismiss
          showBackdrop={false}
          onDidDismiss={() => setOpenAgenda(false)}
        >
          <MyBookingCalendar onClose={() => setOpenAgenda(false)} />
        </IonModal>

        {showActionAlert && (
          <ActionAlert
            isOpen={showActionAlert}
            mode="ios"
            subHeader={t('alerts.logout-header', { clientName })}
            message={`<p>${t('alerts.message')}</p>`}
            buttons={[
              {
                text: t('cancel'),
                cssClass: 'confirm-button',
                role: 'destructive',
                handler: () => {
                  setShowActionAlert((value) => !value);
                },
              },
              {
                text: t('alerts.confirm'),
                cssClass: 'cancel-button',
                role: 'cancel',
              },
            ]}
            onWillDismiss={(e) => {
              if (e.detail.role === 'cancel') {
                dispatch(setLoadingState(`${t('logout')}...`));
                menuController.close('menu').then(() => {
                  setTimeout(() => {
                    dispatch(resetLoadingState());
                    dispatch(unlogUser());
                    history.replace('/login');
                  }, 500);
                });
              }
            }}
          />
        )}
      </IonContent>
    </IonMenu>
  );
});

export default Sidebar;
