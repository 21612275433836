import React, { useEffect, useState } from 'react';
import { IonAlert, IonicSafeString } from '@ionic/react';
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  warningOutline,
} from 'ionicons/icons';

import variables from '../../theme/variables.module.scss';
import { useAppSelector } from '../../store/hooks';
import './alert.scss';

export const ALERT_PROPERTY: {
  [key: string]: { color: string; icon: string };
} = {
  success: { color: variables.success, icon: checkmarkCircleOutline },
  warning: { color: variables.warning, icon: alertCircleOutline },
  error: { color: variables.danger, icon: warningOutline },
};

const Alert = () => {
  const { alerts } = useAppSelector((state) => state.alerts);
  const [alert, setAlert] = useState<{
    type: string;
    message: string;
    duration?: number;
  }>({
    type: '',
    message: '',
    duration: undefined,
  });
  const [show, setShow] = useState(false);
  const { color, icon } = ALERT_PROPERTY[alert.type] ?? {
    color: variables.warning,
    icon: alertCircleOutline,
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const alertElm = alerts[alerts.length - 1];
      setAlert(alertElm);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, alertElm.duration ?? 2000);
    }
  }, [alerts]);

  const onClose = () => {
    setShow(false);
  };

  const messageToast = new IonicSafeString(`
    <ion-icon class="alert-icon" src="${icon}" style="color: ${color}"></ion-icon>
    <p class="alert-text" data-testid="alert-message">${alert.message}</p>
    <div class="alert-footer" style="background-color: ${color}"></div>
  `);

  return (
    <IonAlert
      mode="ios"
      isOpen={show}
      onDidDismiss={onClose}
      cssClass="alert-custom"
      message={messageToast}
    />
  );
};

export default Alert;
