import { useQuery } from '@apollo/client';

import { NotificationsData } from 'types/notification';
import Client from '../client';
import { NotificationsInput } from '../client/modules/notifications';
import { useAppSelector } from '../store/hooks';
import { clientID } from '../store/slices/auth';
import { projectID } from '../store/slices/info';
import { NotificationsState } from '../store/slices/notifications';

const useNotificationsList = () => {
  const notificationsInput: NotificationsInput = {
    client: useAppSelector(clientID),
    project_id: useAppSelector(projectID),
  };
  const { data, loading, error, refetch } = useQuery<NotificationsData>(
    Client.GET_NOTIFICATIONS_LIST,
    Client.GET_NOTIFICATIONS_LIST_DEFAULT_OPTIONS(notificationsInput),
  );

  const filterNotificationsBy = (
    notifications: NotificationsState | undefined,
    filterBy: 'all' | 'archived',
  ) => {
    if (notifications) {
      return {
        data: notifications.data.filter((notification) =>
          filterBy === 'archived'
            ? notification.archived ||
              ['CANCELLED', 'REFUSED', 'CLOSED'].includes(notification.state)
            : !notification.archived &&
              ['SENT', 'READ', 'IN_PROGRESS'].includes(notification.state),
        ),
        count: notifications.count,
      };
    }
    return undefined;
  };

  return {
    notifications: data
      ? filterNotificationsBy(data?.getClientNotifications, 'all')
      : undefined,
    archivedNotifications: data
      ? filterNotificationsBy(data?.getClientNotifications, 'archived')
      : undefined,
    loading,
    error,
    refetchNotifications: refetch,
  };
};

export default useNotificationsList;
