import React from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

import './install-app-alert.scss';
import { useTranslation } from '@tecma/i18n';

interface InstallAppAlertProps {
  closeAlert: () => void;
  promptToInstall?: () => void;
  mobileDeviceType: 'ios' | 'android' | '';
  whereIsShare: 'top' | 'bottom';
  isSafari: boolean;
}

const InstallAppAlert = React.forwardRef<HTMLDivElement, InstallAppAlertProps>(
  (
    { closeAlert, promptToInstall, isSafari, mobileDeviceType, whereIsShare },
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <div
        data-testid="installAppAlert-container"
        ref={ref}
        className={`install-alert ${whereIsShare}`}
      >
        {mobileDeviceType === 'ios' ||
        (mobileDeviceType === 'android' && promptToInstall) ? (
          <div
            data-testid="installAppAlert-promptToInstall"
            className={`install-alert-content${
              mobileDeviceType === 'ios' && isSafari ? '-ios' : ''
            } ${whereIsShare}`}
          >
            <div
              data-testid="installAppAlert-buttonClose"
              aria-hidden="true"
              role="button"
              tabIndex={0}
              onClick={() => closeAlert()}
              className="install-alert-close"
            >
              <IonIcon icon={closeCircleOutline} />
            </div>
            {mobileDeviceType === 'ios' ? (
              isSafari ? (
                <>
                  <IonText
                    data-testid="installAppAlert-contentText"
                    className="install-alert-content-text"
                  >
                    <p>
                      {t('pwa.ios.install')}
                      {whereIsShare === 'bottom' ? ' IPhone' : ' IPad'}:
                    </p>
                  </IonText>
                  <div className="install-alert-content-icon-content">
                    {t('pwa.ios.hit')}
                    <IonIcon
                      className="install-alert-content-icon"
                      src="assets/svg/share-ios.svg"
                    />
                  </div>
                  <IonText className="install-alert-content-text">
                    <p>
                      {t('pwa.ios.click')}
                      <span className="install-alert-content-text-bold">
                        {t('pwa.ios.home')}
                      </span>
                    </p>
                  </IonText>
                </>
              ) : (
                <>
                  <IonText className="install-alert-content-text">
                    <p>{t('pwa.ios.no-safari')}</p>
                  </IonText>
                </>
              )
            ) : mobileDeviceType === 'android' && promptToInstall ? (
              <>
                <IonText className="install-alert-content-text">
                  <p>{t('pwa.android.install')}</p>
                </IonText>
                <IonButton
                  mode="ios"
                  data-testid="installAppAlert-buttonPrompt"
                  type="button"
                  size="small"
                  fill="outline"
                  expand="block"
                  className="install-alert-content-button"
                  onClick={() => promptToInstall()}
                >
                  {t('pwa.android.button')}
                </IonButton>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
);

export default InstallAppAlert;
