/* eslint-disable camelcase */
import { gql } from '@apollo/client';

const CLIENT_APARTMENTS_DATA = `
    id
    name
    floor
    code
    order
    price
    sides {
      id
      building{
        id
        geo{
          lat
          lon
        }
      }
    }
    discounted_price
    enabled_web
    enabled_desk
    active
    status
    updatedOn
    forRent
    extraInfo
    availableQuadrants
`;

export const GET_CLIENT_APARTMENTS = gql`
  query getClientAppartments ($project_id: ID!, $client_id: ID!) {
    getClientAppartments (project_id :$project_id, client_id: $client_id) {
      ${CLIENT_APARTMENTS_DATA}
    }
  }`;

export interface ClientApartmentsInput {
  project_id: string;
  client_id: string;
}

export interface GetClientApartmentsOptions {
  variables: ClientApartmentsInput;
  fetchPolicy: 'no-cache';
}

export const GET_CLIENT_APARTMENTS_DEFAULT_OPTIONS = (
  clientApartmentsInput: ClientApartmentsInput,
): GetClientApartmentsOptions => ({
  variables: clientApartmentsInput,
  fetchPolicy: 'no-cache',
});
