import {
  OperationVariables,
  useQuery,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

export default function useImperativeQuery<
  TData,
  TVariables = OperationVariables,
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {},
): QueryResult<TData, TVariables>['refetch'] {
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    skip: true,
  });

  return (queryVariables: TVariables) => refetch(queryVariables);
}
