import { ApolloProvider } from '@apollo/client';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import React from 'react';
import { setupIonicReact } from '@ionic/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  setServiceWorkerReady,
  setServiceWorkerUpdating,
  setServiceWorkerUpdateComplete,
} from 'store/slices/app';
import { initI18n } from '@tecma/i18n';
import { injectContextHeaders } from '@tecma/logs-fe';
import App from './App';
import ServiceWorkerLoader from './components/ServiceWorker/ServiceWorkerLoader';
import reportWebVitals from './reportWebVitals';
import API from './client/GraphQlClient';
import store from './store';
import translation from './i18n/translations/en/translation.json';

injectContextHeaders();
setupIonicReact();

initI18n(
  translation,
  {
    bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
    productName: `${process.env.REACT_APP_PRODUCT_NAME}`,
    apiBase: `${process.env.REACT_APP_API_URI}`,
  },
  'it-IT',
  process.env.REACT_APP_PSEUDO_LANG,
).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={API()}>
          <App />
        </ApolloProvider>
        <ServiceWorkerLoader
          onReady={() => {
            store.dispatch(setServiceWorkerReady());
          }}
          onUpdating={() => {
            store.dispatch(setServiceWorkerUpdating());
          }}
          onUpdateComplete={() => {
            store.dispatch(setServiceWorkerUpdateComplete());
            window.location.reload();
          }}
        />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
defineCustomElements(window);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
