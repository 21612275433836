import React from 'react';
import { IonImg, IonSpinner } from '@ionic/react';

import './loading-screen.scss';

const LoadingScreen = React.forwardRef<HTMLDivElement>((_, ref) => (
  <div
    data-testid="loadingScreen-container"
    ref={ref}
    style={ref ? { zIndex: 102, opacity: 1, position: 'absolute' } : {}}
    className="loading-screen-page"
  >
    <div className="loading-screen-content">
      <IonImg src="assets/images/app-name.png" />
      <IonSpinner className="loading-screen-content-loader" name="crescent" />
    </div>
  </div>
));

export default LoadingScreen;
