import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Notification } from 'types/notification';

export interface NotificationsState {
  data: Notification[];
  count: number;
}

export const initialState: NotificationsState = {
  data: [],
  count: 0,
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationsState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
