import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera';
import { useState } from 'react';
import { resizeFile } from 'utils';
import { logger } from '@tecma/logs-fe';

export interface UserPhoto extends Photo {
  filepath: string;
  webviewPath?: string;
  previewBase64: string;
  saved: boolean;
}

const usePhotoGallery = () => {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  const removePhoto = (index: number) => {
    setPhotos((currentPhotos) => {
      return [
        ...currentPhotos.slice(0, index),
        ...currentPhotos.slice(index + 1),
      ];
    });
  };

  const takePhoto = async (source: CameraSource) => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        quality: 50,
        allowEditing: true,
        source,
        saveToGallery: true,
      });
      const b64Data = `data:image/${cameraPhoto.format};base64,${cameraPhoto.base64String}`;
      const base64Response = await fetch(b64Data);
      const blob = await base64Response.blob();
      const resizedFile = await resizeFile(blob, 70);
      const fileName = `${new Date().getTime()}.jpeg`;
      const newPhoto: UserPhoto = {
        saved: false,
        format: 'jpeg',
        filepath: fileName,
        base64String: resizedFile
          .toString()
          .split('data:image/jpeg;base64,')?.[1],
        previewBase64: resizedFile.toString(),
      };
      const newPhotos = [newPhoto, ...photos];
      setPhotos(newPhotos);
    } catch (error) {
      // eslint-disable-next-line no-console
      logger.info('');
    }
  };

  const resetPhotos = () => setPhotos([]);

  return { photos, takePhoto, removePhoto, resetPhotos };
};
export default usePhotoGallery;
