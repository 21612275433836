import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

import Client from 'client';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createAlert } from 'store/slices/alert';
import { projectID } from 'store/slices/info';

interface ConfirmUserForm {
  code: string;
  newPassword: string;
  confirmPassword: string;
}

const useConfirmAccount = () => {
  const [confirmUserForm, setConfirmUserForm] = useState<ConfirmUserForm>({
    code: '',
    newPassword: '',
    confirmPassword: '',
  });
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const queryValues = new URLSearchParams(search);
  const projectId = useAppSelector(projectID);
  const token = queryValues.get('token');

  useEffect(() => {
    if (!token) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MUTATIONS
  const [doConfirmUser, { loading }] = useMutation(Client.DO_CONFIRM_USER);

  const handleConfirmUser = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!token) {
      dispatch(
        createAlert({
          type: 'error',
          message: t('alerts.invalid-token'),
        }),
      );
    }
    if (
      !confirmUserForm.code ||
      !confirmUserForm.confirmPassword ||
      !confirmUserForm.newPassword
    )
      return;
    if (
      confirmUserForm.newPassword === confirmUserForm.confirmPassword &&
      token
    ) {
      const mutationData = {
        password: confirmUserForm.code,
        newPassword: confirmUserForm.newPassword,
        project_id: projectId,
        token,
      };

      const { data } = await doConfirmUser(
        Client.DO_CONFIRM_USER_OPTIONS(mutationData),
      );
      if (data.confirmUserAndChangePassword === 'wrongPassword') {
        dispatch(
          createAlert({
            type: 'error',
            message: t('alerts.invalid-code'),
          }),
        );
      } else if (data.confirmUserAndChangePassword) {
        dispatch(
          createAlert({
            type: 'success',
            message: t('alerts.account-confirmed'),
          }),
        );
        history.push('/login');
      }
    } else {
      dispatch(
        createAlert({
          type: 'error',
          message: t('alerts.mismatch-password'),
        }),
      );
    }
  };

  return { confirmUserForm, setConfirmUserForm, handleConfirmUser, loading };
};

export default useConfirmAccount;
