import { FormEvent, useEffect, useReducer, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

import Client from 'client';
import NOTIFICATION_ERRORS from 'client/errors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LoginData } from 'client/modules/auth';
import { IToken, IUser, IClient } from 'types/auth';
import { logUser, setClient, setToken } from 'store/slices/auth';
import { ILocationState } from 'routes/navigation';
import { createAlert } from 'store/slices/alert';
import { resetLoadingState, setLoadingState } from 'store/slices/app';
import Cookies from 'js-cookie';

const loginReducer = (
  state: { email: string; password: string },
  action: { type: 'email' | 'password'; payload: string },
) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const useLogin = () => {
  const passwordInputRef = useRef<HTMLIonInputElement>(null);
  const emailInputRef = useRef<HTMLIonInputElement>(null);
  const [loginForm, dispatchForm] = useReducer(loginReducer, {
    email: '',
    password: '',
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const lastRoute = location.state?.from?.pathname;
  const projectID = useAppSelector((state) => state.info.id);

  // MUTATIONS
  const [doLogin] = useMutation<LoginData>(Client.DO_LOGIN);
  const [doCheckClient] = useMutation(Client.CHECK_CLIENT_INFO);

  useEffect(() => {
    Cookies.remove('jwt');
  }, []);

  const loggedInStuff = async ({
    token,
    user,
    client,
  }: {
    token: IToken;
    user: IUser;
    client: IClient;
  }) => {
    const redirectURL = client.cookies ? '/' : '/welcome';
    dispatch(setToken(token));
    dispatch(logUser(user));
    dispatch(setClient(client));
    dispatch(
      createAlert({
        type: 'success',
        message: t('login.success-alert'),
      }),
    );
    history.replace(lastRoute || redirectURL, {
      from: { pathname: lastRoute || '/' },
    });
  };

  const handleOnChangeValue = (action: {
    type: 'email' | 'password';
    payload: string;
  }) => {
    dispatchForm(action);
  };

  const handleLogin = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!loginForm.email || !loginForm.password) return;
    dispatch(setLoadingState(t('loading')));
    const checkClientData = {
      email: loginForm.email,
      project_id: projectID,
    };
    try {
      const { data: checkClientResp } = await doCheckClient(
        Client.CHECK_CLIENT_INFO_OPTIONS(checkClientData),
      );
      if (checkClientResp.checkClientInfo.status === 'userAlreadyRegistered') {
        const loginData = {
          email: loginForm.email,
          password: loginForm.password,
          project_id: projectID,
        };
        try {
          const { data } = await doLogin(Client.DO_LOGIN_OPTIONS(loginData));
          // eslint-disable-next-line no-underscore-dangle
          if (data && data.loginMyLiving.__typename.endsWith('Error')) {
            dispatch(
              createAlert({
                type: 'error',
                message: t(
                  `errors.${NOTIFICATION_ERRORS[data.loginMyLiving.error]}`,
                ),
              }),
            );
          } else if (data && data.loginMyLiving) {
            loggedInStuff(data.loginMyLiving);
          }
        } catch (error) {
          dispatch(
            createAlert({
              type: 'error',
              message: t('errors.generic-w-error', { error }),
            }),
          );
        } finally {
          dispatch(resetLoadingState());
        }
      } else if (
        checkClientResp.checkClientInfo.status === 'createClientAndUser'
      ) {
        dispatch(
          createAlert({ type: 'error', message: t('login.error-alert') }),
        );
      }
    } catch {
      dispatch(createAlert({ type: 'error', message: t('login.error-alert') }));
    } finally {
      dispatch(resetLoadingState());
    }
  };

  const handlePressEnter = (
    event: React.KeyboardEvent<HTMLIonInputElement>,
  ) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      if (event.currentTarget.name === 'emailLogin') {
        handleOnChangeValue({
          type: 'password',
          payload: '',
        });
        passwordInputRef.current?.setFocus();
      } else if (event.currentTarget.name === 'loginPassword') {
        handleLogin();
      }
    }
  };

  return {
    loginForm,
    passwordInputRef,
    emailInputRef,
    handlePressEnter,
    setLoginForm: handleOnChangeValue,
    handleLogin,
  };
};

export default useLogin;
