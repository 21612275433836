import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAppSelector } from '../store/hooks';
import { userIsLogged } from '../store/slices/auth';
import { INavigationRoute } from './navigation';

export type PublicRouteProps = RouteProps & INavigationRoute;

const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  ...rest
}): ReactElement => {
  const isAuthenticated = useAppSelector(userIsLogged);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PublicRoute;
