/* eslint-disable no-console */
import { CombinedState } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { logger } from '@tecma/logs-fe';

import { RootState } from '.';

export const saveToLocalStorage = (state: RootState) => {
  try {
    const serialisedState = JSON.parse(
      JSON.stringify(pick<CombinedState<RootState>>(state, ['auth'])),
    );
    delete serialisedState.auth.loading;
    localStorage.setItem('persistantState', JSON.stringify(serialisedState));
  } catch (e) {
    logger.warn(e);
  }
};

export const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem('persistantState');
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    logger.warn(e);
    return undefined;
  }
};
