import { useEffect, useState } from 'react';

interface IPWAState {
  promptIntercepted: boolean;
  isStandalone: boolean;
  deferredPrompt: IBeforeInstallPromptEvent | null;
  userInstalled: boolean;
  whereIsShare: 'bottom' | 'top';
  isChrome: boolean;
  isExplorer: boolean;
  isExplorer11: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isOpera: boolean;
  isEdgeDesktop: boolean;
  isEdgeiOS: boolean;
  isEdgeAndroid: boolean;
  userAgent: string;
  isIOS: boolean;
  isMobile: boolean;
  promptSaved: boolean;
  customButtonClicked: boolean;
  deferredPromptShown: boolean;
  deferredPromptRejected: boolean;
  hideAlert: boolean;
}
interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

const useInstallPWA = () => {
  const [pwaState, setPwaState] = useState<IPWAState>({
    promptIntercepted: false,
    isStandalone: false,
    deferredPrompt: null,
    userInstalled: false,
    whereIsShare: 'bottom',
    isChrome: false,
    isExplorer: false,
    isExplorer11: false,
    isFirefox: false,
    isSafari: false,
    isOpera: false,
    isEdgeDesktop: false,
    isEdgeiOS: false,
    isEdgeAndroid: false,
    userAgent: '',
    isIOS: false,
    isMobile: false,
    promptSaved: false,
    customButtonClicked: false,
    deferredPromptShown: false,
    deferredPromptRejected: false,
    hideAlert: false,
  });

  useEffect(() => {
    checkUserAgent();
    trackStandalone();
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      const promptIntercepted = true;
      const deferredPrompt = event as IBeforeInstallPromptEvent;
      const promptSaved = true;
      setPwaState((currentState) => ({
        ...currentState,
        promptIntercepted,
        deferredPrompt,
        promptSaved,
      }));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUserAgent = () => {
    const uaString = navigator.userAgent.toLowerCase();
    let isSafari = /safari/.test(uaString);
    let isChrome = /chrome/.test(uaString);
    const isExplorer = /msie/.test(uaString);
    const isExplorer11 = /rv=11/.test(uaString);
    const isFirefox = /firefox/.test(uaString);
    const isOpera = /opr/.test(uaString);
    const isEdgeDesktop = /edge/.test(uaString);
    const isEdgeiOS = /edgios/.test(uaString);
    const isEdgeAndroid = /edga/.test(uaString);
    const isIOS =
      /ipad|iphone|ipod/.test(uaString) ||
      (navigator?.maxTouchPoints > 2 && /macintosh/.test(uaString));
    const isMobile = /mobile/.test(uaString);
    const whereIsShare =
      /ipad/.test(uaString) ||
      (navigator?.maxTouchPoints > 2 && /macintosh/.test(uaString))
        ? 'top'
        : 'bottom';
    if (isChrome && isSafari) {
      isSafari = false;
    }
    if (isChrome && (isEdgeDesktop || isEdgeiOS || isEdgeAndroid)) {
      isChrome = false;
    }
    if (isSafari && (isEdgeDesktop || isEdgeiOS || isEdgeAndroid)) {
      isSafari = false;
    }
    if (isChrome && isOpera) {
      isChrome = false;
    }
    setPwaState((currentState) => ({
      ...currentState,
      isChrome,
      isExplorer,
      isExplorer11,
      isFirefox,
      isSafari,
      isOpera,
      isEdgeDesktop,
      isEdgeiOS,
      isEdgeAndroid,
      isIOS,
      isMobile,
      whereIsShare,
    }));
  };

  const trackStandalone = () => {
    if (checkStandalone()) {
      setPwaState((currentState) => ({ ...currentState, isStandalone: true }));
    }
  };

  const checkStandalone = (): boolean => {
    return window.matchMedia('(display-mode: standalone)').matches;
  };

  const trackInstalled = () => {
    setPwaState((currentState) => ({ ...currentState, userInstalled: true }));
  };

  const addToHomeScreen = () => {
    setPwaState((currentState) => ({
      ...currentState,
      customButtonClicked: true,
      hideAlert: true,
    }));
    if (!pwaState.deferredPrompt) {
      return;
    }
    pwaState.deferredPrompt.prompt();
    pwaState.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        setPwaState((currentState) => ({
          ...currentState,
          userInstalled: true,
          hideAlert: true,
        }));
        return;
      }
      setPwaState((currentState) => ({
        ...currentState,
        deferredPromptRejected: true,
        hideAlert: true,
      }));
    });
  };

  const showHide = (checkWhat: boolean) => {
    if (checkWhat) {
      return 'block';
    }
    return 'none';
  };

  const onPhoneDetected = () => {
    const { isSafari, isIOS, isStandalone, promptIntercepted, userInstalled } =
      pwaState;
    if (isSafari && isIOS && !isStandalone) {
      return 'ios';
    }
    if (promptIntercepted && !userInstalled) {
      return 'android';
    }
    return '';
  };

  const showHideButtonIOS = () => {
    const { userInstalled, isIOS } = pwaState;
    if (isIOS && !userInstalled) {
      return 'block';
    }
    return 'none';
  };

  return {
    pwaState,
    checkUserAgent,
    checkStandalone,
    onPhoneDetected,
    showHideButtonIOS,
    trackStandalone,
    addToHomeScreen,
    trackInstalled,
    showHide,
  };
};

export default useInstallPWA;
