/* eslint-disable react/no-array-index-key */
import React, { ReactElement } from 'react';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import Sidebar from 'components/Sidebar';
import { disableSidebarMenu } from 'utils';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import navigationRoutes from './navigation';

const Routes = (): ReactElement => {
  const { pathname } = useLocation();

  return (
    <IonSplitPane disabled={disableSidebarMenu(pathname)} contentId="main">
      <Sidebar swipeGesture={!disableSidebarMenu(pathname)} />
      <IonRouterOutlet id="main">
        {navigationRoutes.map(({ component, ...routeParams }, index) => {
          return routeParams.private ? (
            <PrivateRoute key={index} {...routeParams} component={component} />
          ) : (
            <PublicRoute key={index} {...routeParams} component={component} />
          );
        })}
        <Route render={() => <Redirect to="/" />} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default Routes;
