/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Action,
  CombinedState,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';
import { weatherApi } from 'services/weather';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import activitiesReducer from './slices/activity';
import alertReducer from './slices/alert';
import appReducer from './slices/app';
import apartmentsReducer from './slices/apartments';
import authReducer from './slices/auth';
import infoReducer from './slices/info';
import messagesReducer from './slices/messages';
import notificationsReducer from './slices/notifications';
import pushNotificationsReducer from './slices/pushNotifications';

const rootReducer = combineReducers({
  auth: authReducer,
  info: infoReducer,
  notifications: notificationsReducer,
  activities: activitiesReducer,
  messages: messagesReducer,
  apartments: apartmentsReducer,
  app: appReducer,
  alerts: alertReducer,
  pushNotifications: pushNotificationsReducer,
  [weatherApi.reducerPath]: weatherApi.reducer,
});

const storeOptions = {
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware().concat(weatherApi.middleware)],
  devTools: process.env.NODE_ENV !== 'production',
};

const store = configureStore({
  ...storeOptions,
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export const getPreloadedStore = (
  preloadedState: PreloadedState<CombinedState<NoInfer<RootState>>>,
) =>
  configureStore({
    ...storeOptions,
    preloadedState,
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
