import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

import {
  Notification as Messages,
  NotificationState,
} from 'types/notification';

export interface MessagesState {
  data: Messages[];
  count: number;
}

export const initialState: MessagesState = {
  data: [],
  count: 0,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<MessagesState>) => {
      return { ...state, ...action.payload };
    },
    setMessageToRead: (state, action: PayloadAction<string>) => {
      const updateMessages = state.data.map((message) => {
        if (message.id === action.payload) {
          return { ...message, state: NotificationState.READ };
        }
        return message;
      });
      return { ...state, data: updateMessages };
    },
  },
});

export const { setMessages, setMessageToRead } = messagesSlice.actions;
export const getAllMessages = (state: RootState) => state.messages.data;
export const getFirstMessage = (state: RootState) => state.messages.data[0];
export const getNewMessages = (state: RootState) =>
  state.messages.data.filter((message) => message.state !== 'READ');
export const getReadMessages = (state: RootState) =>
  state.messages.data.filter((message) => message.state === 'READ');

export default messagesSlice.reducer;
