import React, { ReactElement, useEffect } from 'react';
import { IonButton, IonIcon, IonSpinner, useIonAlert } from '@ionic/react';
import { CameraSource } from '@capacitor/camera';
import { useTranslation } from '@tecma/i18n';

import { useUserAvatar, usePhotoGallery } from 'hooks';
import './avatar.scss';
import { useAppSelector } from 'store/hooks';
import { userName } from 'store/slices/auth';

const Avatar: React.FC = () => {
  const { t } = useTranslation();
  const {
    avatarImg,
    isPlaceholder,
    loading: loadingAvatar,
    updateAvatar,
  } = useUserAvatar();
  const { photos, takePhoto } = usePhotoGallery();
  const clientName = useAppSelector(userName);
  const [present] = useIonAlert();

  useEffect(() => {
    if (photos.length) {
      updateAvatar(photos[0].previewBase64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  const getAvatar = (): ReactElement => {
    return isPlaceholder ? (
      <IonIcon className="avatar-icon" src={avatarImg} />
    ) : (
      <img className="avatar-image" alt="avatar" src={avatarImg} />
    );
  };

  return (
    <div data-testid="avatar-container" className="avatar-container">
      <div className="avatar-container-icon">
        {!loadingAvatar ? (
          getAvatar()
        ) : (
          <div className="avatar-image-loader">
            <IonSpinner />
          </div>
        )}
      </div>
      <IonButton
        className="avatar-link"
        mode="ios"
        onClick={() => {
          present({
            mode: 'ios',
            cssClass: 'avatar-edit-photo-alert',
            header: t('avatar.header', { clientName }),
            subHeader: t('avatar.sub-header'),
            buttons: [
              {
                text: t('avatar.photo'),
                cssClass: 'avatar-edit-photo-alert-action',
                role: 'photo',
              },
              {
                text: t('avatar.gallery'),
                cssClass: 'avatar-edit-photo-alert-action',
                role: 'gallery',
              },
              {
                text: t('avatar.cancel'),
                cssClass: 'avatar-edit-photo-alert-cancel',
                role: 'destructive',
              },
            ],
            onWillDismiss: (e) => {
              if (e.detail.role === 'photo') {
                takePhoto(CameraSource.Camera);
              } else if (e.detail.role === 'gallery') {
                takePhoto(CameraSource.Photos);
              }
            },
          });
        }}
        type="button"
        fill="clear"
      >
        <IonIcon src="assets/svg/edit.svg" />
      </IonButton>
    </div>
  );
};

export default Avatar;
