import React, { Suspense, useEffect, useRef } from 'react';
import { IonApp, IonLoading } from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import { gsap } from 'gsap';
import Routes from 'routes/Routes';
import Alert from 'components/Alert';
import ErrorHandler from 'components/ErrorHandler';
import SplashScreen from 'components/SplashScreen';
import TokenCountdown from 'components/TokenCountdown';
import { SplashScreen as NativeSplashScreen } from '@capacitor/splash-screen';
import LoadingScreen from 'components/LoadingScreen';
import InstallAppAlert from 'components/InstallAppAlert';
import { useInstallApp, useProjectInfo } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { getLoadingState } from 'store/slices/app';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/core.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
/* App styles */
import 'theme/style.scss';
/* Theme variables */
import 'theme/variables.scss';
/* Fonts */
import 'theme/fonts.scss';

const App: React.FC = () => {
  const splashScreenRef = useRef<HTMLInputElement>(null);
  const installAppAlertRef = useRef<HTMLDivElement>(null);
  const { data, loading, error, refetch } = useProjectInfo();
  const loadingState = useAppSelector(getLoadingState);
  const { pwaState, onPhoneDetected, addToHomeScreen } = useInstallApp();
  const { isStandalone, isIOS, isSafari, hideAlert, whereIsShare } = pwaState;

  useEffect(() => {
    NativeSplashScreen.show();
  }, []);

  useEffect(() => {
    if (data && !loading) {
      NativeSplashScreen.hide();
      gsap.to(splashScreenRef.current, {
        opacity: '0',
        delay: 1,
      });
      gsap.to(splashScreenRef.current, {
        display: 'none',
        delay: 1.2,
        onComplete: () => {
          gsap.to(installAppAlertRef.current, {
            display: 'opacity',
            opacity: '0',
          });
          gsap.to(installAppAlertRef.current, {
            display: 'flex',
            opacity: 1,
            delay: 0.2,
          });
        },
      });
    }
  }, [data, loading]);

  const handleCloseInstallAlert = () => {
    gsap.to(installAppAlertRef.current, {
      display: 'flex',
      opacity: '0',
    });
    gsap.to(installAppAlertRef.current, {
      display: 'none',
      delay: 0.2,
    });
  };

  const promptToInstall = () =>
    onPhoneDetected() === 'android' ? addToHomeScreen : undefined;

  if (loading) return <SplashScreen />;

  if (error)
    return (
      <div className="error-handler-page">
        <ErrorHandler refetchFn={refetch} />
      </div>
    );

  if (data) {
    return (
      <>
        <SplashScreen ref={splashScreenRef} />
        <IonApp className="app-container">
          <Suspense fallback={<LoadingScreen />}>
            <IonReactRouter>
              <Routes />
            </IonReactRouter>
          </Suspense>
          {!isStandalone && (isIOS || !hideAlert) ? (
            <InstallAppAlert
              ref={installAppAlertRef}
              whereIsShare={whereIsShare}
              isSafari={isSafari}
              mobileDeviceType={onPhoneDetected()}
              promptToInstall={promptToInstall}
              closeAlert={() => handleCloseInstallAlert()}
            />
          ) : null}
          <Alert />
          <IonLoading
            cssClass="my-custom-class"
            mode="ios"
            isOpen={loadingState.active}
            show-backdrop
            message={loadingState.text}
          />
        </IonApp>
        <TokenCountdown threshold={process.env.REACT_APP_TIMEOUT_TOKEN} />
      </>
    );
  }

  return <></>;
};

export default App;
