import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Alert } from 'types/notification';

export interface PushNotificationsState {
  data: Alert[];
}

export const initialState: PushNotificationsState = {
  data: [],
};

export const pushNotificationsSlice = createSlice({
  name: 'pushNotifications',
  initialState,
  reducers: {
    setPushNotifications: (state, action: PayloadAction<Alert[]>) => {
      return { ...state, data: [...action.payload] };
    },
  },
});

export const getAllPushNotifications = (state: RootState) =>
  state.pushNotifications.data;
export const { setPushNotifications } = pushNotificationsSlice.actions;

export default pushNotificationsSlice.reducer;
