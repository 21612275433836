import React from 'react';
import { IonText } from '@ionic/react';
import { useTranslation } from '@tecma/i18n';

import { siderbarApartmentInfo } from 'store/slices/apartments';
import { useAppSelector } from '../../store/hooks';
import { userCompleteName } from '../../store/slices/auth';
import './user-info.scss';

const UserInfo: React.FC = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <IonText data-testid="userInfo-text1" className="user-info-name">
        <p>{useAppSelector(userCompleteName)}</p>
      </IonText>
      <IonText data-testid="userInfo-text2" className="user-info-details">
        <p>
          {`${t('apartment')} `}
          <span>{useAppSelector(siderbarApartmentInfo).aptName}</span>
        </p>
        <p>
          {`${t('floor')} `}
          <span>{useAppSelector(siderbarApartmentInfo).floor}°</span>
        </p>
      </IonText>
    </>
  );
});

export default UserInfo;
