/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { register } from '../../serviceWorkerRegistration';

/**
 * Component for register a service-worker for the application
 *
 * exposes callback functions for every service-worker registration state
 *
 * more details here :
 * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
 *
 */

interface ServiceWorkerProps {
  onError?: () => void;
  /**
   *  callback to be performed in case of service-worker installed
   */
  onUpdateActivating: () => void;
  /**
   * callback to be performed in case of an updated service-worker waiting for activation
   */
  onUpdateWaiting: () => void;
  /**
   * callback to be performed in case of updated service-worker activated and ready
   */
  onUpdateActivated: () => void;
  /**
   * callback to be performed in case of first service-worker activated
   */
  onActivated?: () => void;
  /**
   * callback to be performed in case of service-worker ready
   */
  onReady: (registration?: ServiceWorkerRegistration) => void;
  /**
   * If it's true starts actual service worker replacement with waiting worker
   */
  initSWUpdate: boolean;
  children: JSX.Element;
}

const ServiceWorker: React.FC<ServiceWorkerProps> = (props) => {
  const {
    onError,
    onUpdateWaiting,
    onUpdateActivating,
    onUpdateActivated,
    onActivated,
    onReady,
    initSWUpdate = true,
    children,
  } = props;

  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>();

  /**
   * Callback peformed in case of updated service worker found
   *
   * @param {*} registration
   */
  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    if (onUpdateWaiting) onUpdateWaiting();
    setWaitingWorker(registration.waiting);
  };

  /**
   * Adds callback functions to waiting worker onstatechange
   * waitingWorker starts with state 'installed'
   * if SKIP_WAITING message is posted then waitingWorker steps in 'activating'
   * and then to 'activated' as soon as waitingWorker replaces actual service worker
   */
  useEffect(() => {
    if (waitingWorker) {
      waitingWorker.onstatechange = () => {
        if (waitingWorker.state === 'activating') {
          if (onUpdateActivating) onUpdateActivating();
        }
        if (waitingWorker.state === 'activated') {
          if (onUpdateActivated) onUpdateActivated();
        }
      };
    }
  }, [waitingWorker]);

  /**
   * If initSWUpdate is true and waitingWorker exist then post SKIP_WAITING to waiting worker
   * triggering activation of waiting service worker
   */
  useEffect(() => {
    if (waitingWorker && initSWUpdate) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }, [initSWUpdate]);

  /**
   * On component mount starts service worker registration
   * passing callback functions
   */
  useEffect(() => {
    register({
      onUpdate: onSWUpdate,
      onReady,
      onSuccess: onActivated,
      onError,
    });
  }, []);

  return <>{children}</>;
};

export default React.memo(ServiceWorker);
