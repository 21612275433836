import React, { useState } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { useTranslation } from '@tecma/i18n';
import ServiceWorker from './ServiceWorker';

// styles
import './service-worker.scss';

/**
 * Wrapper component for ServiceWorker
 *
 * Returns a notification in case of service worker updated found
 * allowing user to trigger SKIP_WAITING for waiting service worker
 *
 * Exposes callbacks for service-worker installation states
 *
 */

export interface ServiceWorkerLoaderProps {
  onError?: () => void;
  /**
   * Callback to be performed on updated service worker activating
   */
  onUpdating: () => void;
  /**
   * Callback to be performed on updated service worker activated
   */
  onUpdateComplete: () => void;
  /**
   * Callback to be performed on service worker ready,
   * Refers to actual service worker
   *
   * Service worker can be ready even if there is a service worker waiting for replacement
   */
  onReady: () => void;
}

const ServiceWorkerLoader: React.FC<ServiceWorkerLoaderProps> = (props) => {
  const { onError, onUpdating, onUpdateComplete, onReady } = props;
  const { t } = useTranslation();

  const [showPageUpdate, setShowPageUpdate] = useState(false);
  const [pageUpdate, setPageUpdate] = useState(false);

  /**
   * Closes update request while updating and performs onUpdating callback func
   */
  const onUpdateActivating = () => {
    if (onUpdating) onUpdating();
    setShowPageUpdate(false);
  };

  /**
   * Shows update request to user if ther's an update available
   */
  const onUpdateWaiting = () => {
    setShowPageUpdate(true);
  };

  /**
   * Enable update to new page version
   */
  const handlePageUpdate = () => {
    setPageUpdate(true);
  };

  return (
    <ServiceWorker
      onError={onError}
      onUpdateWaiting={onUpdateWaiting}
      onUpdateActivating={onUpdateActivating}
      onUpdateActivated={onUpdateComplete}
      onReady={onReady}
      initSWUpdate={pageUpdate}
    >
      {showPageUpdate ? (
        <div className="service-worker-notification">
          <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol size="auto">
                <IonText>{t('new-version-available')}</IonText>
              </IonCol>
              <IonCol>
                <IonButton
                  mode="ios"
                  fill="solid"
                  size="small"
                  className="service-worker-button"
                  onClick={handlePageUpdate}
                >
                  {pageUpdate ? (
                    <IonSpinner name="crescent" />
                  ) : (
                    <IonText>{t('update')}</IonText>
                  )}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ) : (
        <></>
      )}
    </ServiceWorker>
  );
};

export default React.memo(ServiceWorkerLoader);
