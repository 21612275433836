import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CurrentWeatherData } from 'types/weather';

export const weatherApi = createApi({
  reducerPath: 'weather',
  keepUnusedDataFor: 60 * 60 * 2,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://weatherapi-com.p.rapidapi.com',
    prepareHeaders: (headers: Headers) => {
      headers.set(
        'x-rapidapi-key',
        process.env.REACT_APP_WEATHER_API_KEY ?? '',
      );
      headers.set('x-rapidapi-host', 'weatherapi-com.p.rapidapi.com');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getForecastWeather: builder.query({
      query: (city: string) => ({
        url: `forecast.json?q=${city}&days=7&lang=it`,
        keepUnusedDataFor: 60 * 60 * 2,
      }),
    }),
    getCurrentWeather: builder.query<CurrentWeatherData, string>({
      query: (coords: string) => ({
        url: `/current.json?q=${coords}&lang=it`,
        keepUnusedDataFor: 60 * 60 * 2,
      }),
    }),
  }),
});

export const { useGetForecastWeatherQuery, useGetCurrentWeatherQuery } =
  weatherApi;
