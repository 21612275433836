import { ClientError } from 'client/errors';

/* eslint-disable camelcase */
export enum NotificationType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  MESSAGE = 'MESSAGE',
}

export enum NotificationNoticeType {
  URGENT = 'URGENT',
  GENERIC = 'GENERIC',
  OTHER = 'OTHER',
  COMMUNITY = 'COMMUNITY',
  MESSAGES = 'MESSAGES',
  LIFE_STYLE = 'LIFE_STYLE',
}

export enum NotificationPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  APOCALYPSE = 'APOCALYPSE',
}

export enum NotificationClientBreakdown {
  FORNITURE = 'FORNITURE',
  WINDOW = 'WINDOW',
  CONDITIONING = 'CONDITIONING',
  BATHROOM = 'BATHROOM',
  ROLLER_SHUTTER = 'ROLLER_SHUTTER',
  OTHER = 'OTHER',
}

export enum NotificationBreakdown {
  FORNITURE = 'FORNITURE',
  WINDOW = 'WINDOW',
  CONDITIONING = 'CONDITIONING',
  BATHROOM = 'BATHROOM',
  ROLLER_SHUTTER = 'ROLLER_SHUTTER',
  OTHER = 'OTHER',
  WATER_HEATER = 'WATER_HEATER',
  WATER = 'WATER',
  PACKAGE = 'PACKAGE',
  FIRE = 'FIRE',
  COVID = 'COVID',
  LIGHT = 'LIGHT',
  COMMUNICATIONS = 'COMMUNICATIONS',
  GAS = 'GAS',
  GREEN = 'GREEN',
}

export enum NotificationState {
  SENT = 'SENT',
  READ = 'READ',
  IN_PROGRESS = 'IN_PROGRESS',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
}

export enum NotificationCreationUser {
  CM = 'CM',
  CLIENT = 'CLIENT',
}

export enum NotificationClientNoticeType {
  URGENT = 'URGENT',
  GENERIC = 'GENERIC',
  OTHER = 'OTHER',
}

export interface Attachment {
  type: string;
  base64: string;
}

export interface AttachmentInput {
  type: string;
  base64: string;
}

export interface Notification {
  id: string;
  subject: string;
  message: string;
  type: NotificationType[];
  notice: NotificationNoticeType;
  priority: NotificationPriority;
  breakdown: NotificationBreakdown | null;
  read: boolean;
  state: NotificationState;
  client: string | null;
  buildingOnly: boolean;
  validated: boolean;
  archived: boolean;
  cancelled: boolean;
  createdOn: string;
  createdBy: NotificationCreationUser;
  updatedOn: string;
  deletedOn: string | null;
  attachments: Attachment[];
  appartment: string | null;
  building: string | null;
  project_id: string;
}
export interface NotificationsData {
  getClientNotifications: {
    data: Notification[];
    count: number;
  };
}

export interface NotificationData {
  getNotification: Notification & ClientError;
}

export interface NotificationStatusDetail {
  message: string;
  icon: string;
}
export type NotificationsStatusDetail = {
  [key: string]: NotificationStatusDetail;
};

export interface Alert {
  id: string;
  subject: string;
  message: string;
  breakdown: NotificationBreakdown;
  notice: NotificationNoticeType;
  isMessage: boolean;
  isReply: boolean;
  createdOn: string;
  status?: string;
}

export interface PushNotificationsData {
  getAlerts: Alert[];
}
