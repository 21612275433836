import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import Client from '../client';
import { projectID } from '../store/slices/info';
import { clientID, selectClient } from '../store/slices/auth';
import { ClientData } from '../client/modules/auth';
import { resetLoadingState, setLoadingState } from '../store/slices/app';

const useAppPermissions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientInfo = useAppSelector(selectClient);
  const clientInfoData = {
    project_id: useAppSelector(projectID),
    id: useAppSelector(clientID),
  };
  const [doUpdatePermissions] = useMutation<ClientData>(
    Client.UPDATE_CLIENT_INFO,
  );

  const updatePermissions = async () => {
    dispatch(setLoadingState(t('loading')));
    const updateClientsData = {
      ...clientInfoData,
      clientInput: {
        cookies: true,
        firstName: clientInfo.firstName,
        lastName: clientInfo.lastName,
        tel: clientInfo.tel,
        email: clientInfo.email,
      },
    };
    await doUpdatePermissions(
      Client.UPDATE_CLIENT_INFO_DEFAULT_OPTIONS(updateClientsData),
    );
    dispatch(resetLoadingState());
  };

  return {
    updatePermissions,
  };
};

export default useAppPermissions;
